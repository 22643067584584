<template lang="pug">
modal(@click='$emit("close")')
  modal-body
    template(v-slot:header)
      v-heading Importar contatos
    template(v-slot:body)
      modal(mo2 v-if="isSelectCategoryModalOpen" @click='isSelectCategoryModalOpen=false')
        modal-body
          template(v-slot:header)
            v-heading Categorias
          template(v-slot:body)
            v-dropdown(placement='bottom-end' auto-size)
              button.bg-zinc-50.border.px-4.py-2.rounded-md.flex.justify-between.gap-4
                p.font-semibold.text-zinc-500 {{ selectedCategory?`'${selectedCategory.name}' selecionada`:'Selecione uma categoria' }}
                .material-icons.notranslate.text-zinc-500 expand_more
              template(#popper)
                .max-h-96.flex.flex-col.divide-y.justify-start
                  .flex.px-2.py-1.w-full.h-20
                    icon-input(v-model="categoriesNameSearch" label="Procurar categoria")
                  .flex.flex-col.divide-y.w-full(class='child:px-2 child:py-1')
                    .cursor-pointer.text-zinc-500.text-sm.w-full.h-10.flex.gap-4.justify-start.w-full(
                      v-for="category in categories.filter(category=>category.name.toLowerCase().startsWith(categoriesNameSearch.toLowerCase()))" :key="category.id"
                      @click="selectedCategory = category"
                      class='hover:bg-zinc-100'
                    )
                      .material-icons.notranslate.text-sm sell
                      p.text-sm.overflow-hidden(:title="category.name") {{ category.name }}
          template(v-slot:footer)
            .flex.gap-4
              v-button-secondary(@click='isSelectCategoryModalOpen=false') Cancelar
              v-button(@click='selectCategory(selectedCategory)') OK
      .w-96.h-48.bg-zinc-100.rounded-md.border.relative.flex.justify-center.items-center.cursor-pointer(v-if="!loading && !importedContactData.length")
        .flex.flex-col.gap-4.w-full.absolute.items-center.justify-center.p-8.cursor-pointer
          .material-icons.notranslate.text-3xl.text-zinc-400 download
          p.text-xs.text-zinc-400.text-center Clique aqui para fazer o upload ou arraste e solte o arquivo
        input.w-full.h-full.opacity-0(
          type="file",
          name="",
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="uploadFile"
        )

      div.flex.flex-col.gap-4(
          v-else-if='!loading && importedContactData.length'
          class='max-w-[800px] relative'
      )
        .flex.flex-col.gap-2.w-full
          .w-full.h-full.border-2.border-amber-500.p-4.rounded-md.gap-4.bg-amber-50.box-border(v-show='importedContactData.length >= 1500')
            p.font-semibold.text-amber-500 Atenção!
            p.text-sm O arquivo selecionado atingiu o limite de 1500 contatos para a inserção em massa e não pode não ter sido carregado por completo
          .w-full.h-full.border-2.border-red-500.p-4.rounded-md.gap-4.bg-red-50.box-border(v-show="showErrorMessage()")
            p.font-semibold.text-red-500 Atenção!
            p.text-sm Valores invalidos não serão salvos na nossa base de dados, mesmo que a importação seja feita com sucesso
          .flex.gap-4.w-full.justify-between
            .flex.justify-start.items-center.gap-2.w-full
              p.text-sm.font-semibold Usar cabeçalho:
              switch-checkbox(v-model="importedFileHasHeaders")
            .flex.justify-start
              v-dropdown(placement='bottom-end')
                button.bg-zinc-50.border.px-4.py-2.rounded-md.flex.justify-between.gap-4(
                  :class="[{'opacity-50': !selectedContactList.length}]"
                )
                  p.font-semibold.text-zinc-500 Ações
                  .material-icons.notranslate.text-zinc-500 expand_more
                template(#popper)
                  .flex.flex-col.divide-y(
                    v-if="selectedContactList.length"
                    class='child:px-2 child:py-1 child:list-none'
                  )
                    li.cursor-pointer.text-zinc-500.text-sm(
                      @click="isSelectCategoryModalOpen=true"
                      class='hover:bg-zinc-100'
                    ) Categorizar contato(s)
        .max-h-96.overflow-auto.relative.w-full(:class="{ 'overflow-y-hidden': isFileTooLarge }")
          v-table
            template(v-slot:header)
              v-table-head
                td
                  input(type="checkbox", name="" v-model="areAllContactsSelected" :disabled="isFileTooLarge")
                td(v-for="(head, headIndex) in importedContactHeaders" :key='head')
                  .flex.gap-2.flex-col.items-start
                    p.font-semibold.text-brand {{ head }}
                    p.text-xs usar como:

                    select.px-2.py-1.cursor-pointer(
                      v-model="headerToContactDataDictionary[headIndex]"
                      :class="{'border border-red-500 shadow shadow-red-500': hasErrorsHeader(headIndex)}"
                      @change="useOnChangeSelectionHooks(headIndex)"
                    )

                      option(value="" selected disabled hidden)
                      option(v-for="field in contactFields" :key="field[1]" :value="field[1]") {{field[0]}}
                td
            template(v-slot:body)
              v-table-row(v-for="(contactData, rowIndex) in importedContactDataDisplay" :key='rowIndex' :isFileTooLarge="isFileTooLarge")
                td
                  input(type="checkbox" v-model="selectedContactList" :value="rowIndex" :disabled="isFileTooLarge")

                v-table-data(
                  v-for='(data, dataIndex) in contactData'
                  :key='dataIndex'
                  :data='data'
                  :row-index='rowIndex'
                  :is-phone-column="isFileTooLarge ? false : ((currentPhoneColumn === dataIndex) || (currentSecondPhoneColumn === dataIndex))"
                  :is-cpf-column="isFileTooLarge ? false : (currentCpfColumn === dataIndex)"
                  :cpf-has-error="cpfHasError"
                  :phone-number-has-error="phoneNumberHasError"
                  @error="handleRowError"
                )

                td
                  .flex.gap-2.items-start
                    .px-3.py-1.rounded-full.flex.justify-center.gap-4.border(
                      v-if="contactCategoryIndexMap[rowIndex]"
                    )
                      p {{  contactCategoryIndexMap[rowIndex]?.name }}
                      .material-icons.notranslate.text-sm.cursor-pointer(@click='removeCategoryFromContact(rowIndex)') close
                    tooltip(v-else)
                      .material-icons.notranslate.text-zinc-500.text-sm.cursor-pointer(@click="selectIndividualCategory(rowIndex)") sell
                      template(#popper)
                        p Categorizar contato

        .absolute.bg-white.z-20.rounded.shadow-md.shadow-gray-400.p-5(
            v-if="isFileTooLarge"
            class="bottom-[30px] h-content w-[85%]"
        )
          h3.font-inter.font-bold.text-base Atenção!
          p O arquivo é muito grande e por isso não pode ser exibido por completo.
          p E algumas funções de edição serão desabilitadas.

        span(
          v-if="isFileTooLarge"
          class="z-10 absolute h-1/2 inline-block w-full bottom-[14px] right-0 bg-gradient-to-t from-white to-transparent"
        )

      div(v-else-if='loading')
        .flex.w-full.h-full.justify-center.items-center
         loading-component.w-24
    template(v-slot:footer)

      .flex.gap-4
        v-button-secondary(@click='$emit("close")' :disabled="loading") Cancelar
        v-button(@click='transformImportedDataToContactPayload' :disabled="loading") Importar dados
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import vTable from '@/components/Tables/v-table.vue';
import vTableHead from '@/components/Tables/v-table-head.vue';
import vTableRow from '@/components/Tables/v-table-row.vue';
import vTableData from './ImportModalTableData.vue';
import vHeading from '@/components/Text/v-heading.vue';
import { parse } from 'papaparse';
import { PhoneNumberUtil } from 'google-libphonenumber';
import vButton from '@/components/Buttons/v-button.vue';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import SwitchCheckbox from '@/components/Inputs/SwitchCheckbox.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import contactList from '@/services/contact-list';
import { Dropdown, Tooltip, hideAllPoppers } from 'floating-vue';
const phoneNumberUtilInstance = PhoneNumberUtil.getInstance();

export default {
  components: {
    Modal,
    ModalBody,
    vHeading,
    vTable,
    vTableHead,
    vTableRow,
    vTableData,
    vButton,
    IconInput,
    vButtonSecondary,
    LoadingComponent,
    SwitchCheckbox,
    Tooltip,
    VDropdown: Dropdown,
  },
  data() {
    return {
      cpfHasError: false,
      phoneNumberHasError: false,
      loading: false,
      selectedContactIndex: null,
      selectedCategory: null,
      categoriesNameSearch: '',
      categories: [],
      isSelectCategoryModalOpen: false,
      contactCategoryIndexMap: {},
      areAllContactsSelected: false,
      headerToContactDataDictionary: {},
      importedFileHasHeaders: true,
      importedContactData: [],
      importedContactHeaders: [],
      selectedContactList: [],
      contactFields: [
        ['Nome', 'name'],
        ['Sobrenome', 'lastname'],
        ['Email', 'email'],
        ['Telefone 1', 'phone'],
        ['Telefone 2', 'phone2'],
        ['CPF', 'cpf'],
      ],
      isFileTooLarge: false,
    };
  },
  computed: {
    importedContactDataDisplay() {
      if (this.isFileTooLarge) return this.importedContactData.slice(0, 6);
      return this.importedContactData;
    },
    currentPhoneColumn() {
      let phoneColumnIndex;
      Object.keys(this.headerToContactDataDictionary).forEach((key) => {
        const value = this.headerToContactDataDictionary[key];
        if (value !== 'phone') return;
        phoneColumnIndex = key;
      });
      return parseInt(phoneColumnIndex);
    },
    currentSecondPhoneColumn() {
      let phoneColumnIndex;
      Object.keys(this.headerToContactDataDictionary).forEach((key) => {
        const value = this.headerToContactDataDictionary[key];
        if (value !== 'phone2') return;
        phoneColumnIndex = key;
      });
      return parseInt(phoneColumnIndex);
    },
    currentEmailColumn() {
      let phoneColumnIndex;
      Object.keys(this.headerToContactDataDictionary).forEach((key) => {
        const value = this.headerToContactDataDictionary[key];
        if (value !== 'email') return;
        phoneColumnIndex = key;
      });
      return parseInt(phoneColumnIndex);
    },
    currentNameColumn() {
      let phoneColumnIndex;
      Object.keys(this.headerToContactDataDictionary).forEach((key) => {
        const value = this.headerToContactDataDictionary[key];
        if (value !== 'name') return;
        phoneColumnIndex = key;
      });
      return parseInt(phoneColumnIndex);
    },
    currentLastnameColumn() {
      let phoneColumnIndex;
      Object.keys(this.headerToContactDataDictionary).forEach((key) => {
        const value = this.headerToContactDataDictionary[key];
        if (value !== 'lastname') return;
        phoneColumnIndex = key;
      });
      return parseInt(phoneColumnIndex);
    },
    currentCpfColumn() {
      let phoneColumnIndex;
      Object.keys(this.headerToContactDataDictionary).forEach((key) => {
        const value = this.headerToContactDataDictionary[key];
        if (value !== 'cpf') return;
        phoneColumnIndex = key;
      });
      return parseInt(phoneColumnIndex);
    },
  },
  methods: {
    handleRowError(field) {
      if (field === 'cpf') {
        this.cpfHasError = true;
      } else if (field === 'phoneNumber') {
        this.phoneNumberHasError = true;
      }
    },
    hasErrorsHeader(dataIndex) {
      return (
        (this.currentPhoneColumn === dataIndex && this.phoneNumberHasError) ||
        (this.currentSecondPhoneColumn === dataIndex && this.phoneNumberHasError) ||
        (this.currentCpfColumn === dataIndex && this.cpfHasError)
      );
    },
    showErrorMessage() {
      return (
        (this.currentPhoneColumn && this.phoneNumberHasError) ||
        (this.currentCpfColumn && this.cpfHasError) ||
        (this.currentSecondPhoneColumn && this.phoneNumberHasError)
      );
    },
    removeCategoryFromContact(contactIndex) {
      delete this.contactCategoryIndexMap[contactIndex];
      this.contactCategoryIndexMap = { ...this.contactCategoryIndexMap };
    },
    selectIndividualCategory(contactIndex) {
      this.selectedContactIndex = contactIndex;
      this.isSelectCategoryModalOpen = true;
    },
    selectCategory(category) {
      if (this.selectedContactList.length) {
        let categoryMap = { ...this.contactCategoryIndexMap };
        this.selectedContactList.forEach((contact) => {
          categoryMap[contact] = category;
        });
        this.contactCategoryIndexMap = categoryMap;
      } else if (this.selectedContactIndex === 0 || this.selectedContactIndex) {
        let map = { ...this.contactCategoryIndexMap };
        map[this.selectedContactIndex] = category;
        this.contactCategoryIndexMap = map;
      }
      this.selectedContactIndex = null;
      this.selectedCategory = null;
      this.isSelectCategoryModalOpen = false;
    },
    toggleAllContactSelection() {
      if (this.areAllContactsSelected) this.selectedContactList = this.importedContactData.map((data, index) => index);
      else this.selectedContactList = [];
    },
    uploadFile(event) {
      this.loading = true;
      this.importedContactData = [];
      let isFirstRow = true;
      const headerDictionary = {};
      parse(event.target.files[0], {
        step: (row, parser) => {
          if (row.data.length <= 1) return;

          if (this.importedContactData.length >= 1500) return parser.abort();

          if (!isFirstRow) {
            return this.importedContactData.push(row.data);
          }

          this.importedContactHeaders = row.data;

          this.importedContactHeaders.forEach((header) => {
            headerDictionary[header] = '';
          });

          this.headerToContactDataDictionary = headerDictionary;

          isFirstRow = false;
        },
        complete: (results, file) => {
          if (this.importedContactData.length >= 300) {
            this.isFileTooLarge = true;
            this.areAllContactsSelected = true;
          }
          this.loading = false;
        },
      });
    },
    useHeaderAsUserData(header) {
      this.headerToContactDataDictionary[header];
      this.headerToContactDataDictionary = {};
    },

    useOnChangeSelectionHooks(header) {
      this.clearAnyOldSelectionOfSameValue(header);
    },

    clearAnyOldSelectionOfSameValue(header) {
      const value = this.headerToContactDataDictionary[header];
      Object.keys(this.headerToContactDataDictionary).forEach((_, headerIndex) => {
        const headerValue = this.headerToContactDataDictionary[headerIndex];

        if (header === headerIndex) return;
        if (value !== headerValue) return;

        this.headerToContactDataDictionary[headerIndex] = '';
      });
    },
    isValidPhoneNumber(phone) {
      try {
        const parsedPhone = phoneNumberUtilInstance.parse(phone, 'BR');
        return phoneNumberUtilInstance.isValidNumber(parsedPhone);
      } catch (error) {
        return false;
      }
    },
    async transformImportedDataToContactPayload() {
      const contactNameCol = this.currentNameColumn;
      const contactPhoneCol = this.currentPhoneColumn;
      const contactSecondPhoneCol = this.currentSecondPhoneColumn;
      const contactEmailCol = this.currentEmailColumn;
      const contactLastnameCol = this.currentLastnameColumn;
      const contactCpfCol = this.currentCpfColumn;

      if ((!contactNameCol || !contactPhoneCol) && contactNameCol !== 0 && contactPhoneCol !== 0)
        return this.$toast.error('Defina quais colunas usar como nome e telefone para seus contatos');

      const payload = this.importedContactData.map((importedData, index) => {
        const contact = { emails: [], phones: [], name: '', categoryId: null };
        const name = importedData[contactNameCol] || '';
        const categoryId = this.contactCategoryIndexMap[index]?.id;

        if (categoryId) contact.categoryId = categoryId;
        if (importedData[contactLastnameCol]) contact.name = `${name} ${importedData[contactLastnameCol]}`;
        else contact.name = name;
        contact.belongsTo = this.user.company_id;

        if (importedData[contactEmailCol]) contact.emails.push({ address: importedData[contactEmailCol] });

        const cpf = importedData[contactCpfCol];
        if (cpf) {
          const isValidCpf = cpf.match(/\d{11}/);
          if (isValidCpf) contact.cpf = cpf;
        }

        const phone1 = importedData[contactPhoneCol];
        const phone2 = importedData[contactSecondPhoneCol];
        const phones = [phone1, phone2];

        phones.forEach((phone) => {
          if (!phone) return;
          const isValidPhone = this.isValidPhoneNumber(phone);
          try {
            const libPhone = phoneNumberUtilInstance.parse(phone, 'BR');
            if (isValidPhone)
              contact.phones.push({
                ddi: libPhone.getCountryCode(),
                number: libPhone.getNationalNumber(),
                country: 'BR',
                companyId: this.user.company_id,
              });
          } catch (error) {}
        });

        return contact;
      });

      try {
        this.loading = true;
        await contactList.bulkCreateContacts(payload);
        this.$toast.success('Contatos importados com sucesso');
        this.loading = false;
        this.$emit('close');
      } catch (error) {
        const defaultMessage = 'Não foi possivel concluir a operação';

        const message = error?.response?.data?.validation?.body?.message ?? defaultMessage;

        this.$toast.error(message);

        this.loading = false;
      }
    },
  },
  watch: {
    areAllContactsSelected() {
      this.toggleAllContactSelection();
    },
    importedFileHasHeaders() {
      if (!this.importedFileHasHeaders) {
        this.importedContactData.unshift(this.importedContactHeaders.slice());
        this.importedContactHeaders = this.importedContactHeaders.map((header, index) => index);
      } else {
        this.importedContactHeaders = this.importedContactData.shift();
      }
    },
    selectedCategory() {
      if (!this.selectedCategory) return;
      hideAllPoppers();
    },
  },
  async created() {
    try {
      this.categories = await contactList.getCategoryList();
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
