<template lang="pug">
.flex.flex-col.w-full(
  :class="[isAutomaticMessage ? 'items-center mb-0' : isActualAgentMessage ? 'items-end mb-3' : 'items-start mb-3']",
)
  observer(v-if="!isAutomaticMessage || !isActualAgentMessage" @intersect="updateMessageStatusToRead")
  .rounded.p-2.max-w-sm(:class="[isAutomaticMessage ? 'bg-zinc-100' : isActualAgentMessage ? 'bg-zinc-200' : 'bg-zinc-600']")
    section(v-if="message?.replyingTo")
      replying.mb-1(:replying-to="message.replyingTo")
    section(v-if="message?.messageHeader")
      component(:is="`header-${message.messageHeaderType}`" :message="message" :is-automatic="isAutomaticMessage")
    section(v-if="message.messageBody")
      .flex.gap-1.items-center.justify-center.cursor-help(
        v-if="message?.isPrivate"
        v-tooltip="'Essa resposta foi definida como confidencial, ou seja, você não poderá visualiza-lá.'"
      )
        .material-icons.notranslate.text-gray-400.text-xs lock
        p.text-zinc-400.font-plus.text-sm Mensagem confidencial
      .flex.gap-1.items-center.justify-center.cursor-help(
        v-else-if="message?.isConfidential"
        v-tooltip="'Essa mensagem pertence a um grupo privado, ou seja, você não poderá visualiza-la a menos que faça parte dele.'"
      )
        .material-icons.notranslate.text-gray-400.text-xs visibility_off
        p.text-zinc-400.font-plus.text-sm Mensagem privada
      component(v-else :is="`chat-${message.messageType}`" :message="message" :is-automatic="isAutomaticMessage")
    section(v-if="message?.attachments?.length")
      chat-attachment(v-for="(attachment, index) in message.attachments" :key="index" :attachment="attachment")
    section(v-if="message?.messageFooter")
      span.text-xs.text-center.text-gray-400 {{ message.messageFooter }}
    section.flex.flex-col.gap-2.items-center.mt-2(v-if="message?.buttons?.length")
      chat-buttons(v-for="(button, index) in message?.buttons" :key="index" :button="button")
  reaction.mt-1(:reactions="message?.reactions", :members="members", v-if="message?.reactions?.length")
  section.flex.gap-2.items-center(v-if="!isAutomaticMessage")
    span.text-zinc-400.text-xs.font-inter {{ $moment(message.createdAt).format('DD/MM/yyyy [-] HH:mm') }}
    chat-message-status(:message-status="message.status" v-if="isActualAgentMessage && message?.status?.length && message?.provider !== 'messenger'", :members="members")
</template>
<script>
import Observer from '../Observer.vue';
import { defineComponent } from 'vue';
import ChatAudio from './audio.vue';
import ChatImage from './image.vue';
import ChatText from './text.vue';
import ChatContacts from './contacts.vue';
import ChatDocument from './document.vue';
import ChatInvitation from './invitation.vue';
import ChatInvitationCancel from './invitation-cancel.vue';
import ChatInvitationReply from './invitation-reply.vue';
import ChatLocation from './location.vue';
import ChatVoice from './voice.vue';
import ChatVideo from './video.vue';
import HeaderImage from './header-image.vue';
import HeaderDocument from './header-document.vue';
import HeaderText from './header-text.vue';
import ChatInteractive from './interactive.vue';
import ChatMessageStatus from './chat-message-status.vue';
import Reaction from './reaction.vue';
import ChatButtons from './buttons.vue';
import Replying from './replying.vue';
import ChatAttachment from './attachments/card.vue';

export default defineComponent({
  name: 'message-card',
  components: {
    ChatAudio,
    ChatImage,
    ChatText,
    ChatContacts,
    ChatDocument,
    ChatInvitation,
    ChatInvitationCancel,
    ChatInvitationReply,
    ChatInteractive,
    ChatVideo,
    ChatVoice,
    HeaderImage,
    HeaderDocument,
    HeaderText,
    ChatMessageStatus,
    Observer,
    ChatLocation,
    Reaction,
    ChatButtons,
    Replying,
    ChatAttachment,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    members: {
      type: Array,
      required: false,
    },
    hasScrollInMessageBox: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeRoomMemberId: {
      type: Number,
      required: false,
    },
  },

  computed: {
    isActualAgentMessage() {
      return this.message?.recipientId === this.user.id;
    },
    isAutomaticMessage() {
      return !this.message.recipientId;
    },
    messageStatus() {
      return this.message?.status.length ? this.message.status : 'null';
    },
  },
  methods: {
    updateMessageStatusToRead() {
      if (!this.message || !this.activeRoomMemberId) return;
      if (!this.message.status) return;

      if (this.message.chatRoomMemberId === this.activeRoomMemberId) return;

      this.$store.commit('chat/addDisplayedMessages', {
        activeRoomMemberId: this.activeRoomMemberId,
        ...this.message,
      });

      if (!this.hasScrollInMessageBox) {
        this.$store.dispatch('chat/markMessagesAsRead');
      }
    },
  },
});
</script>
