<template lang="pug">
//- DialpadContent
.wrapper
  side-bar(v-if="user.type === 'opens'")
    sidebar-link(
      :class="routerLink == 'Preferências' ? 'nav-active' : ''",
      to="/preferences"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Preferências' }")
        span.material-icons.notranslate settings
  side-bar(v-else)
    sidebar-link.relative(
      :class="routerLink == 'ContextArea' ? 'nav-active' : ''",
      v-if="context",
      :to="`/context/${linkedid}`"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'ContextArea' }")
        span.material-icons.notranslate(class="icone") phone
      
      .tooltipText(class="dark:invert")
        span Em chamada
    sidebar-link.relative(
      :class="routerLink == 'Principal' ? 'nav-active' : ''",
      to="/dashboard"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Principal' }")
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe871
      
      .tooltipText(class="dark:invert")
        span Dashboard
    sidebar-link.relative(
      v-if="plan.chat",
      :class="routerLink == 'Chat' ? 'nav-active' : ''",
      to="/chat"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Chat' }")
        span.flex.h-3.w-3.absolute.top-0(v-if="pendingMessages.length > 0")
          span.relative.inline-flex.rounded-full.h-3.w-3.bg-orange-500
          span.animate-ping.absolute.inline-flex.rounded-full.h-3.w-3.bg-orange-500.opacity-75
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe0b7
      
      .tooltipText(class="dark:invert")
        span Chat

    sidebar-link.relative(
      v-if="plan.chat && isAllowedToUseEmail",
      :class="routerLink == 'Emails' ? 'nav-active' : ''",
      to="/emails"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Emails' }")
        span.flex.h-3.w-3.absolute.top-0(v-if="pendingMessages.length > 0")
          span.relative.inline-flex.rounded-full.h-3.w-3.bg-orange-500
          span.animate-ping.absolute.inline-flex.rounded-full.h-3.w-3.bg-orange-500.opacity-75
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe0be
      
      .tooltipText(class="dark:invert")
        span Email

    sidebar-link.relative(
      :class="routerLink == 'Queues' ? 'nav-active' : ''",
      v-if="plan.queuePanel",
      :to="'/queues'"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Queues' }")
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe24b
      
      .tooltipText(class="dark:invert")
        span Filas
    sidebar-link.relative(
      v-if="isAllowedToUseCases",
      :class="routerLink == 'Casos' ? 'nav-active' : ''",
      to="/cases"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Casos' }")
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe85d
      
      .tooltipText(class="dark:invert")
        span Casos
    sidebar-link.relative(
      :class="routerLink == 'Atividades' ? 'nav-active' : ''",
      to="/callback"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Atividades' }")
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe85f
          v-tooltip.absolute(
            class="dark:invert",
            placement="right",
            :distance="newActivity ? 32 : 4",
            class="right-[-10px] top-[-4px]",
            :class="{ 'right-[-4px]': newActivity }"
          )
            Transition(name="slide-fade")
              .h-4.w-4.grid.place-items-center.bg-brand-300.text-white.rounded-full.text-xs.font-bold.font-plus(
                class="dark:text-black dark:invert",
                :class="{ 'min-w-fit px-1': newActivity }"
              ) {{ newActivity ? 'NOVA' : yourCallbacks > 99 ? '99+' : yourCallbacks }}
            template(#popper)
              p.text-xs.font-plus {{ `Você tem ${yourCallbacks} atividade(s)!` }}
          v-tooltip.absolute(placement="right", class="right-[-10px] bottom-[-4px]")
            .h-4.w-4.grid.place-items-center.bg-gray-400.text-black.rounded-full.text-xs.font-bold.font-plus(
              class="dark:text-black"
            ) {{ abandonCallbacks > 99 ? '99+' : abandonCallbacks }}
            template(#popper)
              p.text-xs.font-plus {{ `${abandonCallbacks} atividades não atribuidas!` }}
      
      .tooltipText(class="dark:invert")
        span Atividades
    sidebar-link.relative(
      :class="routerLink == 'Contatos' ? 'nav-active' : ''",
      to="/contacts"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Contatos' }")
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe0ba
      
      .tooltipText(class="dark:invert")
        span Contatos
    sidebar-link.relative(
      :class="routerLink == 'Preferências' ? 'nav-active' : ''",
      to="/preferences"
    )
      .fa(class="dark:invert", :class="{ 'dark:!bg-white': routerLink == 'Preferências' }")
        span.material-icons.notranslate(class="icone dark:text-gray-500") &#xe8b8
      
      .tooltipText(class="dark:invert")
        span Preferências
  .flex.flex-col.items-start.main-panel.bg-neutral-50
    .fixed.w-full.top-0.h-20.z-40.bg-white
      top-navbar
    .h-screen.pt-20.overflow-auto.container-fluid.bg-white.relative
      progress-modal(v-if="$store.getters['GET_MODAL'] === 'Progress'")
      .fixed.z-10.w-full(v-if="activeOverlay == 'Callback'")
        callback-overlay.overflow-overlay.pb-6
      .fixed.z-10.w-full(v-else-if="activeOverlay == 'CreateCase'")
        create-case-overlay.overflow-overlay.pb-6
      .fixed.z-10.w-full(v-else-if="activeOverlay == 'EditCase'")
        edit-case-overlay.overflow-overlay.pb-6
      .fixed.z-10.w-full(v-else-if="activeOverlay == 'DetailCase'")
        detail-case-overlay.overflow-overlay.pb-6
      .fixed.z-10(class="w-3/4" v-else-if="activeOverlay == 'ContactHistory'")
        contact-history-overlay.overflow-overlay.pb-6
      .bg-neutral-50.bg-neutral-50-dark.row.flex.items-start.rounded-tl-3xl(
        class="!ml-0 responsive-border"
      )
        .flex.flex-none.w-full.items-start
          .flex-1.content-div
            portal-target(name="overlay-outlet", slim)
            Transition(name="slide-fade")
              dashboard-content
          div(v-show="!collapseDialer", :class="!collapseDialer ? openDialerClass : ''")
            .fixed.flex.items-start.bg-gray-50
              .absolute.left-0.z-10.flex.items-start.flex-none.w-1(
                @click="toggleDialer(true)"
              )
                p.absolute.flex.items-center.justify-center.mt-8.text-white.rounded-full.cursor-pointer.z-1.w-7.h-7.bg-orange.material-icons.notranslate &#xe409
              dialpad-content(v-on-clickaway="awayDialer")
          .relative.flex.items-start.flex-none.w-12.h-full.cursor-pointer(
            v-if="collapseDialer",
            @click="toggleDialer(false)"
          )
            p.fixed.flex.items-center.justify-center.mt-8.text-white.rounded-full.z-1.w-7.h-7.bg-orange.material-icons.notranslate &#xe5cb
            p.h-full.border-gray-200.cursor-pointer.border-left
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import { Tooltip } from 'floating-vue';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import DialpadContent from './Dialpad';
import MobileMenu from './MobileMenu.vue';
import ProgressModal from '@/components/Modals/ProgressModal.vue';
import CallbackOverlay from '@/components/Overlay/callback-overlay.vue';
import CreateCaseOverlay from '@/components/Overlay/create-case-overlay.vue';
import EditCaseOverlay from '@/components/Overlay/edit-case-overlay.vue';
import DetailCaseOverlay from '@/components/Overlay/detail-case-overlay.vue';
import ContactHistoryOverlay from '@/components/Overlay/contact-history-overlay.vue';
import AddUnknownContactOverlay from '@/components/Overlay/add-unknown-contact-overlay.vue';
import eventBus from '@/main-event-bus.js';
import { countCallbacks } from '@/services/cblist.js';
import user from '@/store/user';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    DialpadContent,
    CallbackOverlay,
    EditCaseOverlay,
    DetailCaseOverlay,
    CreateCaseOverlay,
    ContactHistoryOverlay,
    ProgressModal,
    AddUnknownContactOverlay,
    VTooltip: Tooltip,
  },
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      translateCServiceStatus: {
        enabled: 'Disponivel',
        disabled: 'NaoPerturbe',
        online: 'Disponivel',
        auto: 'Automatico',
      },
      abandonCallbacks: 0,
      yourCallbacks: 0,
      emailWhiteListedCompanies: process.env.VUE_APP_EMAIL_WHITE_LIST,
      casesWhiteListedCompanies: process.env.VUE_APP_CASES_WHITE_LIST,
    };
  },
  computed: {
    isAllowedToUseEmail() {
      if (!this.emailWhiteListedCompanies) return true;
      return this.emailWhiteListedCompanies?.split(',').includes(this.user.company_id);
    },
    isAllowedToUseCases() {
      if (!this.casesWhiteListedCompanies) return true;
      return this.casesWhiteListedCompanies?.split(',').includes(this.user.company_id);
    },
    newActivity() {
      return this.$store.getters['user/GET_NEWACTIVITY'];
    },
    version() {
      const packageJson = require('../../package.json');
      return packageJson.version;
    },
    context() {
      return this.$store.getters['cstate/IS_IN_CONTEXT'];
    },
    call() {
      return this.$store.getters['cstate/GET_CURRENT_CALL'];
    },
    pendingMessages() {
      return this.$store.getters['cstate/PENDING_MESSAGES'];
    },
    routerLink() {
      return this.$route.name;
    },
    noDevice() {
      if (this.devices.sip.length <= 0) return true;
      for (let i = 0; i < this.devices.sip.length; i++) {
        if (this.devices.sip[i].status == 'enabled') {
          return true;
        }
      }
      return false;
    },
    linkedid() {
      return this.$store.getters['cstate/GET_CALL_ID'];
    },
    collapseDialer() {
      return this.$store.getters['collapseDialer'];
    },
    activeOverlay() {
      return this.$store.getters['overlay/activeOverlay'];
    },
    openDialerClass() {
      if (this.windowWidth >= 1366) return 'relative flex items-start pl-2 flex-none w-80 h-full cursor-pointer';
      else return 'absolute top-0 shadow-dialer z-10 w-80 mt-20 flex items-start flex-none right-0';
    },
  },
  watch: {
    context: function () {
      this.$forceUpdate();
    },
    call: function () {
      this.$forceUpdate();
    },
    windowWidth() {
      if (this.windowWidth <= 1366) {
        this.toggleDialer(true);
      } else {
        this.toggleDialer(false);
      }
    },
  },
  async created() {
    eventBus.$on('openDialer', this.toggleDialer);
    eventBus.$on('closeDialer', this.toggleDialer);
    if (!this.user.queues) return;
    let queues = '';
    for (var property in this.user.queues) {
      queues += property + ',';
    }
    try {
      const data = await countCallbacks({
        queues: Object.keys(this.user.queues),
        categories: ['abandon'],
        notAssigned: true,
      });

      const yourData = await countCallbacks({
        assignedTo: [this.user.id],
      });

      this.yourCallbacks = yourData ?? 0;
      this.abandonCallbacks = data ?? 0;
    } catch (error) {
      console.error(error);
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
    if (this.windowWidth <= 1366) {
      this.toggleDialer(true);
    }
  },
  methods: {
    toggleDialer(status) {
      setTimeout(() => {
        this.$store.commit('SET_COLLAPSE_DIALER', status);
      }, 50);
    },
    awayDialer() {
      if (this.collapseDialer) return;
      if (this.windowWidth >= 1366) return;
      this.toggleDialer(true);
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 992px) {
  div.responsive-border,
  a.responsive-border {
    border-top-left-radius: 0px !important;
  }
}

@media (max-height: 820px) {
  .nav-active {
    .link-selected {
      top: 22px !important;
    }
  }
}

.icone {
  transition: 200ms all ease;
  font-size: 26px !important;
  transform: rotate(-45deg);
}

.overflow-overlay {
  height: calc(100vh - 5rem);
  overflow: auto;
}

.align-modal-content {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltipText {
  position: absolute;
  right: -160px;
  color: white;
  background: linear-gradient(29.27deg, #c56672 -53.79%, #482396 97.11%);
  padding: 8px;
  top: 19px;
  transform: translateX(-50%);
  border-radius: 9999px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  width: 120px;
  visibility: hidden;
  opacity: 0;
}

.nav-active {
  .link-selected {
    background: linear-gradient(29.27deg, #c56672 -53.79%, #482396 97.11%);
    width: 12px;
    height: 12px;
    border-radius: 999999px !important;
    position: absolute;
    top: 34px;
    right: -6px;
    bottom: 0;
  }

  .fa {
    background: linear-gradient(29.27deg, #c56672 -53.79%, #482396 97.11%);
    animation: selectedNav 0.2s ease;
  }

  .icone {
    color: white !important;
  }

  .tooltipText {
    visibility: hidden !important;
  }
}

@keyframes selectedNav {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }

  100% {
    opacity: 1;
    transform: rotate(45deg);
  }
}

.content-div {
  padding-right: 0px;
}

.dial-div {
  padding: 0px;
  min-width: 321px;
  height: 100%;
  background-color: #fff;
}

.sidebar-link-label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #868e96;
}

.in-call-status {
  background: var(--secondary-color);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  right: -5px;
  top: -5px;
}

.status-red {
  background: var(--secondary-color);
}

.status-green {
  background: var(--green-default);
}

.Automatico {
  background: #5f95d4;
}
</style>

<style scoped>
.dial-space {
  width: 450px;
}

.shadow-dialer {
  box-shadow: -12px 7px 25px -4px rgba(0, 0, 0, 0.15);
}

.fa {
  padding: 10px;
  border-radius: 8px;
  transform: rotate(45deg);
  text-align: center;
}
</style>
