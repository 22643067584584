import cstateService from '@/services/cstate';
export default {
  methods: {
    async getDeviceRegistry(endpoint) {
      try {
        const status = await cstateService.isDeviceConnected(endpoint);
        this.$store.commit('cstate/SET_IS_DEVICE_CONNECTED', status);
      } catch (error) {
        this.$lo.track('Falha Registro Ramal', { endpoint });
        this.$toast.error('Falha ao buscar registro de seu ramal, por favor tente novamente mais tarde');
        this.$store.commit('cstate/SET_IS_DEVICE_CONNECTED', false);
      }
    },
  },
};
