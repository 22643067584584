import abandon from '@/components/CallEventTrackingTypes/abandon';
import tagRemoved from '@/components/CallEventTrackingTypes/tag-removed.vue';
import agile from '@/components/CallEventTrackingTypes/agile.vue';
import answer from '@/components/CallEventTrackingTypes/answer';
import callback from '@/components/CallEventTrackingTypes/callback';
import callflow from '@/components/CallEventTrackingTypes/callflow.vue';
import ChatInvitation from '@/components/CallEventTrackingTypes/chat-invitation.vue';
import enterqueue from '@/components/CallEventTrackingTypes/enterqueue';
import enterura from '@/components/CallEventTrackingTypes/enterura';
import expired from '@/components/CallEventTrackingTypes/expired';
import hangup from '@/components/CallEventTrackingTypes/hangup';
import idle from '@/components/CallEventTrackingTypes/idle.vue';
import leave from '@/components/CallEventTrackingTypes/leave';
import note from '@/components/CallEventTrackingTypes/note';
import protocol from '@/components/CallEventTrackingTypes/protocol';
import queueanswer from '@/components/CallEventTrackingTypes/queueanswer';
import queuehangup from '@/components/CallEventTrackingTypes/queuehangup';
import queuenoanswer from '@/components/CallEventTrackingTypes/queuenoanswer.vue';
import record from '@/components/CallEventTrackingTypes/record.vue';
import ringing from '@/components/CallEventTrackingTypes/ringing';
import rule from '@/components/CallEventTrackingTypes/rule.vue';
import tag from '@/components/CallEventTrackingTypes/tag';
import terminated from '@/components/CallEventTrackingTypes/terminated.vue';
import timeout from '@/components/CallEventTrackingTypes/timeout';
import transfer from '@/components/CallEventTrackingTypes/transfer';
import starttransfer from '@/components/CallEventTrackingTypes/starttransfer.vue';
import establishedtransfer from '@/components/CallEventTrackingTypes/establishedtransfer.vue';
import sentto_csat from '@/components/CallEventTrackingTypes/sentto_csat.vue';
import forwardemail from '@/components/CallEventTrackingTypes/forwardemail.vue';

export default {
  components: {
    idle,
    abandon,
    answer,
    callback,
    enterqueue,
    enterura,
    tagRemoved,
    sentto_csat,
    hangup,
    leave,
    note,
    protocol,
    queueanswer,
    queuehangup,
    tag,
    transfer,
    timeout,
    expired,
    ringing,
    terminated,
    rule,
    ChatInvitation,
    queuenoanswer,
    agile,
    record,
    callflow,
    starttransfer,
    establishedtransfer,
    forwardemail,
  },
};
