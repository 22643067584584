<template lang="pug">
MovideskModal
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Criar Ticket
    i.cancel.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
  template(v-slot:content)
    .input-aligner.row
      label.up-placeholder Cliente
      input.form-control.input(
        type="text",
        :value="context.client.businessName || context.client.BusinessName",
        name="client",
        readonly
      )

    .input-aligner.row.flex.justify-between
      span.mr-2(class="w-2/5" v-if="movidesk.data1 !== 'flexible'")
        label.up-placeholder Criado por
        input.form-control.input(
          type="text",
          name="createdBy",
          placeholder="Responsável padrão",
          readonly,
          :value="creator.name"
        )
      span.mr-2(class="w-2/5" v-else)
        Dropdown(:visible="creatorDropdownVisibility")
          template(v-slot:input)
            label.up-placeholder Criado por
            input.form-control.input(
              type="text",
              name="createdBy",
              v-model="creator.name",
              @click="open('creatorDropdownVisibility')",
              placeholder="Selecione o criador"
            )
          template(v-slot:options)
            li(
              v-for="(item, index) in personOptions.filter(e => !e.team)" v-if="item.repeat < 2",
              :key="index",
              @click="selectCreator(item)"
            ) {{ item.businessName }}

      span.mr-2(class="w-2/5" v-if="movidesk.data2 !== 'flexible'")
        label.up-placeholder Responsável
        input.form-control.input(
          type="text",
          name="agent",
          placeholder="Responsável padrão",
          readonly,
          :value="responsible.name"
        )
      span.mr-2(class="w-2/5" v-else)
        Dropdown(:visible="responsibleDropdownVisibility")
          template(v-slot:input)
            label.up-placeholder Responsável 
            input.form-control.input(
              type="text",
              name="agent",
              v-model="responsible.name",
              @click="open('responsibleDropdownVisibility')",
              placeholder="Selecione o responsável"
            )
          template(v-slot:options)
            li(
              v-for="(item, index) in personOptions" v-if="item.repeat < 2",
              :key="index",
              @click="selectResponsible(item)"
            )
              .flex.items-center.justify-start(v-if="item.team")
                span.material-icons.notranslate.p-2.cursor-pointer(v-if="item.team") &#xf233
                span.teste(v-if="item.team") {{ item.businessName }}
              span(v-else) {{ item.businessName }}
      

    .input-aligner.row
      label.up-placeholder Título do ticket
      input.form-control.w-full.input(
        name="subject",
        v-model="subject",
        placeholder="Insira um título"
      )

    .input-aligner.row.flex.justify-between 
      span.mr-2(class="w-2/5")
        Dropdown(:visible="categoryDropdownVisibility")
          template(v-slot:input)
            label.up-placeholder Categoria
            input.form-control.input(
              type="text",
              name="category",
              v-model="category",
              @click="open('categoryDropdownVisibility')",
              placeholder="Selecione uma categoria"
            )
          template(v-slot:options)
            li(
              v-for="(item, index) in categories",
              :key="index",
              @click="selectCategory(item)"
            ) {{ item.name }}

      span.mr-2(class="w-2/5")
        Dropdown(:visible="urgencyDropdownVisibility")
          template(v-slot:input)
            label.up-placeholder Urgência
            input.form-control.input(
              type="text",
              name="urgency",
              v-model="urgency",
              @click="open('urgencyDropdownVisibility')",
              placeholder="Selecione a urgência"
            )
          template(v-slot:options)
            li(
              v-for="(item, index) in urgencies",
              :key="index",
              @click="selectUrgency(item)"
            ) {{ item.name }}

    .input-aligner.row
      label.up-placeholder Descrição
      textarea.form-control.input.h-28(
        v-model="description",
        name="description",
        placeholder="Insira uma descrição..."
      )

  template(v-slot:footer)
    .flex.w-full.items-center.justify-end
      p.footer__cancel.mr-16(
        @click="$store.commit('modal/setActiveModal', '')"
      ) Cancelar
      button.margin-top-medium.buttonsave(
        :class="{ disabled: !isReady }",
        @click="createTicket",
        :disabled="!isReady"
      ) CRIAR TICKET
</template>

<script>
import MovideskModal from '@/components/Movidesk/ModalComponent.vue';
import integrationService from '@/services/integrations.js';
import Dropdown from '@/components/Inputs/Dropdown.vue';
import rhService from '@/services/rship.js';

export default {
  components: {
    MovideskModal,
    Dropdown,
  },
  data() {
    return {
      urgencyDropdownVisibility: false,
      categoryDropdownVisibility: false,
      responsibleDropdownVisibility: false,
      creatorDropdownVisibility: false,
      categories: [],
      urgencies: [],
      value: 0,
      subject: '',
      personOptions: [],
      category: '',
      urgency: '',
      description: '',
      responsible: {
        name: '',
        id: '',
        team: '',
      },
      creator: {
        name: '',
        id: '',
      },
    };
  },
  computed: {
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    context() {
      return this.$store.getters['GET_MOVIDESK_CONTEXT'];
    },
    movidesk() {
      return this.$store.getters['GET_MOVIDESK'];
    },
    isReady() {
      if (!this.category) return false;
      if (!this.urgency) return false;
      if (!this.description) return false;
      if (!this.responsible.name) return false;
      if (!this.responsible.id) return false;
      if (!this.responsible.team) return false;
      if (!this.creator.id) return false;
      if (!this.creator.name) return false;
      return true;
    },
  },
  methods: {
    open(key) {
      this[key] = true;
    },
    close(key) {
      this[key] = false;
    },
    selectCreator(creatorSelected) {
      this.creator.name = creatorSelected.businessName;
      this.creator.id = creatorSelected.id;
      this.creatorDropdownVisibility = false;
    },
    selectResponsible(responsibleSelected) {
      this.responsible.name = responsibleSelected.businessName;
      this.responsible.id = responsibleSelected.id;
      this.responsible.team = responsibleSelected.team;

      if (!responsibleSelected.team) {
        this.responsible.team = responsibleSelected.teams.pop();
      }
      this.responsibleDropdownVisibility = false;
    },
    selectCategory(categorySelected) {
      this.category = categorySelected.name;
      this.categoryDropdownVisibility = false;
    },
    selectUrgency(urgencySelected) {
      this.urgency = urgencySelected.name;
      this.urgencyDropdownVisibility = false;
    },
    async setDefaultValues() {
      const personsRequest = `https://api.movidesk.com/public/v1/persons?token=${this.movidesk.token}&$select=id,BusinessName,teams&$filter=id eq '${this.movidesk.data1}' OR id eq '${this.movidesk.data2}'`;
      const persons = await this.$http.get(personsRequest);

      let team = false;
      if (this.movidesk.data2 && this.movidesk.data2.indexOf('team|') >= 0) {
        team = this.movidesk.data2.split('team|');
        this.responsible.name = team[1];
        this.responsible.id = this.movidesk.data2;
        this.responsible.team = team[1];
      }

      persons.data.forEach((person) => {
        if (person.id == this.movidesk.data1) {
          this.creator.name = person.BusinessName || person.businessName;
          this.creator.id = person.id;
        }

        if (!team && person.id == this.movidesk.data2) {
          this.responsible.name = person.BusinessName || person.businessName;
          this.responsible.id = person.id;
          this.responsible.team = person.teams.pop();
        }
      });
    },
    async getAgents() {
      this.personOptions = await integrationService.getAgents(this.movidesk.token);
    },

    async getPresets() {
      try {
        const urgencyRequest = `https://api.movidesk.com/public/v1/urgencies?token=${this.context.movideskToken}`;
        const categoryRequest = `https://api.movidesk.com/public/v1/categories?token=${this.context.movideskToken}`;
        const urgency = await this.$http.get(urgencyRequest);
        const category = await this.$http.get(categoryRequest);
        this.urgencies = urgency.data;
        this.categories = category.data;
        this.category = category.data[0].name;
        this.urgency = urgency.data[0].name;
        this.setDefaultValues();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async createTicket() {
      if (!this.isReady) return;
      try {
        let newTicket = {
          subject: this.subject,
          urgency: this.urgency,
          category: this.category,
          type: 1,
          clients: [{ id: this.context.client.id || this.context.client.Id }],
          actions: [
            {
              origin: 13,
              type: 1,
              description:
                this.description +
                ` <hr> Criado através do Opens, pelo usuário <b>${this.user.username}</b> <br> <b>Gravação:</b> ${window.location.origin}/client/#/historic/detail/${this.call.linkedid}`,
            },
          ],
          createdBy: {
            id: this.creator.id,
          },
        };
        if (typeof this.responsible.team == 'string') {
          if (this.responsible.id.indexOf('team|') >= 0) {
            // team selected in flexible
            newTicket.ownerTeam = this.responsible.team;
          } else {
            // user selected
            newTicket.owner = {
              id: this.responsible.id,
            };
            newTicket.ownerTeam = this.responsible.team;
          }
        } else {
          if (this.responsible.team) {
            // team selected
            newTicket.ownerTeam = this.responsible.id;
          }
        }

        const data = await integrationService.executeAction(
          { actionName: 'create-ticket', partnerName: 'movidesk' },
          {
            data: newTicket,
            companyId: this.user.company_id,
            queryParams: [this.movidesk.token],
          },
        );
        if (this.movidesk.data3 && this.call) {
          await rhService.createTag({
            eventType: 'tag',
            companyId: this.call.company_id,
            identifier: this.user.username,
            callId: this.call.linkedid || this.linkedid,
            text: 'Ticket criado',
          });
        }
        this.$toast.open({
          message: 'Ticket criado com sucesso!',
          type: 'success',
          duration: 2000,
        });
        this.$store.commit('modal/setActiveModal', '');
      } catch (error) {
        this.$toast.open({
          message: 'Não foi possivel criar um ticket...',
          type: 'error',
          duration: 2000,
        });
      }
    },
  },
  async created() {
    try {
      await this.getPresets();
      await this.getAgents();
    } catch (error) {}
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.7;
}
.header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #868e96;
}

.cancel {
  border-radius: 100%;
  margin-top: 20px;
  width: 30px;
  height: 30px;
  color: rgb(197, 197, 197);
  cursor: pointer;
}

.row {
  float: left;
  width: 100%;
  padding-top: 5px;
}

.left {
  width: 50%;
  float: left;
}
.right {
  width: 50%;
  float: rigth;
}

.footer {
  &__cancel {
    cursor: pointer;
    font-size: 14px;
    color: rgb(252, 105, 52);
    font-weight: 600;
  }
}
</style>
