<template lang="pug">
.w-full
    //- Loading esqueleto
    .flex.w-80.h-10.animate-pulse.items-center.select-none.px-4.rounded.justify-center.h-12(
      v-if="loadingAttachment", class="dark:bg-zinc-700"
    )
      .material-icons.notranslate.text-gray-400.bg-gray-300.rounded-full play_arrow
      div.bg-gray-300.h-2.w-full.rounded-full.m-2

    //- Audio
    .flex(v-else-if="!attachment?.error")
      audio-player.w-80(:source="audioUrl", :bg="'bg-transparent'")

    //- Error
    p.text-xs.text-red-500.flex.rounded.px-4.py-2.cursor-pointer.mb-1.bg-opacity-10(
      class="hover:bg-gray-400 hover:bg-opacity-20"
      :class="['bg-zinc-600']"
      v-tooltip="attachment?.error"
      v-else
    ) Error ao carregar áudio
      i.material-icons.notranslate.text-xl.text-red-500.transition-all(
        @click="loadAudio",
        class="hover:text-red-600 hover:rotate-[360deg] duration-300"
        v-tooltip="'Tente novamente'"
      ) refresh
</template>

<script>
import AudioPlayer from '@/components/Player/audio-player.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import { Dropdown } from 'floating-vue';
import { getAttachmentById } from '@/services/records';

export default {
  components: {
    FDropdown: Dropdown,
    DropdownItem,
    AudioPlayer,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      audioUrl: null,
      loadingAttachment: true,
    };
  },

  methods: {
    async loadAudio() {
      try {
        this.loadingAttachment = true;
        const data = await getAttachmentById(this.attachment.id, this.attachment.provider);
        this.audioUrl = data.link;
      } catch (error) {
        this.attachment.error = error?.message ?? 'Erro ao carregar áudio';
      } finally {
        this.loadingAttachment = false;
      }
    },
  },

  async created() {
    await this.loadAudio();
  },
};
</script>
