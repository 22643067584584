import { getUserById } from '@/services/cservice';
import { SessionState } from 'sip.js';
import io from 'socket.io-client';
import eventBus from '../main-event-bus';
import moment from 'moment-timezone';

const bell = new Audio(require('@/assets/sounds/ping-sound.wav'));
const ringingSound = new Audio(require('@/assets/sounds/ringing.mp3'));
const messageAlertSound = new Audio(require('@/assets/sounds/message-alert.mp3'));
const ringingSounds = {
  default: new Audio(require('@/assets/sounds/ringing.mp3')),
  ringing2: new Audio(require('@/assets/sounds/ringing2.mp3')),
  cellphone_ringing: new Audio(require('@/assets/sounds/cellphone_ringing.mp3')),
  office_ringing: new Audio(require('@/assets/sounds/office_ringing.mp3')),
  digitalphone_ringing: new Audio(require('@/assets/sounds/digitalphone_ringing.mp3')),
  ring_ringing: new Audio(require('@/assets/sounds/ring_ringing.mp3')),
};

const dialer = {
  DEFAULT: 0,
  INCOMING_CALL: 1,
  CALLING: 2,
  IN_CALL: 4,
  CALL_ENDED: 5,
};

const status = {
  AUTO: 10,
  CONECTADO: 100,
  DISPONIVEL: 101,
  AGENTE_DISPONIVEL: 102,
  NAO_PERTURBE: 202,
  EM_PAUSA: 303,
  FIM_PAUSA: 304,
  EM_CHAMADA: 404,
  FIM_CHAMADA: 405,
  OFFLINE: 504,
};

export default {
  namespaced: true,
  state: {
    userStatus: '',
    activeDialer: true,
    isDeviceConnected: false,
    socket: null,
    connected: false,
    queueData: {},
    currentCall: null,
    channel: '',
    callPayload: null,
    extraCall: null,
    dialer: 0,
    isInContext: false,
    lastStatusUpdate: Date.now(),
    queueSummary: {},
    pendingMessages: [],
    latency: [],
    spier: '',
    lastPingDate: '',
    queueWaitList: {
      queue: '',
      waitList: {},
    },
    callActivity: 0,
    ringingSound: ringingSound,
    ringingSounds: ringingSounds,
    debounceQueueWorkersToUpdate: [],
    timeoutToUpdateQueueWorkers: null,
  },
  getters: {
    PENDING_MESSAGES: function (state) {
      return state.pendingMessages;
    },
    IS_DIALER_ACTIVE: function (state) {
      return state.activeDialer;
    },
    IS_DEVICE_CONNECTED: function (state) {
      return state.isDeviceConnected;
    },
    GET_CALL_ACTIVITY: function (state) {
      return state.callActivity;
    },
    GET_SOCKET: function (state) {
      return state.socket;
    },
    GET_CURRENT_CALL: function (state) {
      return state.currentCall;
    },
    GET_CHANNEL: function (state) {
      return state.channel;
    },
    GET_CALL_PAYLOAD: function (state) {
      return state.callPayload;
    },
    GET_CALL_ID: function (state) {
      if (state.callPayload) return state.callPayload.linkedid;
      return undefined;
    },
    GET_EXTRA_CALL: function (state) {
      return state.extraCall;
    },
    GET_DIALER_STATE: function (state) {
      return state.dialer;
    },
    IS_IN_CONTEXT: function (state) {
      return state.isInContext;
    },
    GET_QUEUE_SUMMARY: function (state) {
      return state.queueSummary;
    },
    GET_QUEUE_WAIT_LIST: function (state) {
      return state.queueWaitList;
    },
    GET_QUEUE_DATA(state) {
      return state.queueData;
    },
    lastStatusUpdate(state) {
      return state.lastStatusUpdate;
    },
    isConnected(state) {
      return state.connected;
    },
    userStatus(state) {
      return state.userStatus;
    },
    latency(state) {
      return state.latency;
    },
    lastPingDate(state) {
      return state.lastPingDate;
    },
    ringingSound(state) {
      return state.ringingSound;
    },
    ringingSounds(state) {
      return state.ringingSounds;
    },
    spier(state) {
      return state.spier;
    },
  },
  mutations: {
    ADD_CHAT_NOTIFY(state, message) {
      state.pendingMessages.push(message);
    },
    REMOVE_CHAT_NOTIFY(state, id) {
      state.pendingMessages = state.pendingMessages.filter((message) => {
        return message.id !== id;
      });
    },
    SET_IS_DEVICE_CONNECTED(state, payload) {
      state.isDeviceConnected = payload;
    },
    SET_CALL_PAYLOAD(state, payload) {
      state.callPayload = payload;
    },
    SET_QUEUE_SUMMARY(state, payload) {
      state.queueSummary = payload;
    },
    SET_QUEUE_DATA(state, payload) {
      state.queueData = payload;
      state.currentCall = payload.linkedid;
      const newPayload = {
        ...payload,
      };
      if (payload.agent) newPayload.to = payload.agent.peer;
      state.callPayload = newPayload;
      state.dialer = 4;
    },
    SET_QUEUE_WAIT_LIST(state, waitlist) {
      if (!waitlist.waitList) waitlist.waitList = {};
      state.queueWaitList = waitlist;
    },
    SET_SOCKET: function (state, socket) {
      state.socket = socket;
    },
    SET_DATA: function (state, { payload, dialerState }) {
      if (payload.linkedid === state.currentCall) return;
      payload.to = payload.to ? payload.to.replace(/\D/g, '') : '';
      state.channel = `SIP/${payload.to}`;
      state.callPayload = payload;
      state.dialer = dialerState;
    },
    SET_DIALER_ONCALL: function (state, {}) {
      payload.from = payload.from.replace(/\D/g, '');
      state.channel = `SIP/${payload.from}`;
      state.dialer = 4;
      state.currentCall = payload.linkedid;
      state.callPayload = payload;
    },
    SET_ONCALL_DATA: function (state, payload) {
      if (!payload.to || !payload.from) return;
      if (payload.linkedid === state.currentCall) {
        state.extraCall = payload;
        return;
      }
      payload.from = payload.from.replace(/\D/g, '');
      state.channel = `SIP/${payload.from}`;
      state.dialer = 4;
      state.currentCall = payload.linkedid;
      state.callPayload = payload;
    },
    SET_TRANSFER_DATA: function (state, payload) {
      if (payload.from) payload.from = payload.from.replace(/\D/g, '');
      state.channel = `SIP/${payload.endpoint}`;
      state.currentCall = payload.linkedid;
      state.callPayload = payload;
      state.dialer = 4;
    },
    DESTROY: function (state, payload) {
      state.callActivity += 1;
      if (!state.currentCall) state.dialer = 0;
      else state.dialer = 5;
      state.currentCall = null;
      state.channel = null;
    },
    SET_IS_IN_CONTEXT: function (state, context) {
      state.isInContext = context;
    },
    SET_DIALER_STATE: function (state, dialerState) {
      state.dialer = dialerState;
      state.activeDialer = dialerState;
    },
    SET_CHANNEL: function (state, channel) {
      state.channel = channel;
    },
    ADD_WAIT_LIST: function (state, payload) {
      const newList = {
        ...state.queueWaitList,
      };

      newList.waitList[payload.fromId || payload.number] = payload;
      state.queueWaitList = {
        ...newList,
      };
    },
    REMOVE_WAIT_LIST: function (state, payload) {
      const newObj = {
        ...state.queueWaitList,
      };
      delete newObj.waitList[payload.fromId || payload.number];
      state.queueWaitList = newObj;
    },
    REMOVE_FROM_WORKER_LIST: function (state, { queue, number }) {
      state.queueSummary[queue].members = state.queueSummary[queue].members.filter((m) => {
        return m.agent != number;
      });
    },
    ADD_TO_WORKER_LIST: function (state, { queue, number }) {
      state.queueSummary[queue].members.push({
        agent: number,
        name: null,
      });
    },
    // função para atualizar os membros de uma fila no queuesummary
    UPDATE_QUEUE_WORKER: function (state, payload) {
      state.debounceQueueWorkersToUpdate.push(payload);
      clearTimeout(state.timeoutToUpdateQueueWorkers);
      state.timeoutToUpdateQueueWorkers = setTimeout(() => debouncedQueueUpdate(state), 1500);
    },
    setLastStatusUpdate: function (state, timestamp) {
      state.lastStatusUpdate = timestamp;
    },
    isConnected: function (state, connected) {
      state.connected = connected;
    },
    setUserStatus(state, status) {
      state.userStatus = status;
    },
    setPingDate(state, date) {
      state.lastPingDate = date;
    },
    setLatency(state, latency) {
      state.latency.push(latency);
      if (state.latency.length > 5) {
        state.latency = state.latency.slice(state.latency.length - 5, state.latency.length);
      }
    },
    setSpier(state, spier) {
      state.spier = spier;
    },
    setRingingSound(state, ringingSound) {
      state.ringingSound = ringingSound;
    },
  },
  actions: {
    socketConfig({ commit, rootGetters, getters }, { accessToken, endpoint, company_id, user_id, Vue }) {
      const schema = process.env.VUE_APP_WEBSOCKET_SCHEMA;
      const cstate = process.env.VUE_APP_CSTATE;
      const socket = io(`${schema}://${cstate}`, {
        query: {
          token: accessToken,
          device: endpoint,
          room: company_id,
          userId: user_id,
          lifeline: true,
        },
        reconnect: true,
        closeOnBeforeunload: false,
        transports: ['websocket'],
      });

      try {
        let inCall = false;
        commit('SET_SOCKET', socket);
        socket.on('disconnect', () => {
          commit('isConnected', false);
        });
        socket.on('connect', async () => {
          commit('isConnected', true);
          setTimeout(() => {
            socket.emit('join-room', `${Vue.user.endpoint}:${Vue.user.company_id}`);
            socket.emit('latency-ping', {
              code: Math.floor(Math.random() * 100000),
              timestamp: moment().toISOString(),
            });
            commit('setPingDate', moment().toISOString());
          }, 1100);
          if (Vue.user.profile === 'p_agent' || Vue.user.profile === 'p_manager') {
            setTimeout(() => {
              socket.emit('queue-login', {
                token: Vue.accessToken,
              });
            }, 1000);
          }
        });

        socket.on('user-update', (payload) => {
          commit('user/SET_USER', payload, { root: true });
        });
        socket.on('logout', () => {
          Vue.$cookies.remove('snep7');
          window.location.href = '/auth/logout';
        });
        socket.on('spy', ({ spier }) => {
          commit('setSpier', spier);
        });
        socket.on('latency-pong', ({ timestamp, code }) => {
          const latency = moment().diff(timestamp, 'ms');
          commit('setLatency', latency);
        });
        socket.io.on('reconnect', () => {
          commit('setLastStatusUpdate', Date.now());
          eventBus.$emit(`restart-status`);
        });

        inCall = listeningStates({ socket, inCall, commit, getters, endpoint, rootGetters, vue: Vue });
        listeningStatesChat(socket, commit, Vue);
        socket.io.on('reconnect', () => {
          commit('setLastStatusUpdate', Date.now());
          eventBus.$emit(`restart-status`);
        });
        const intervalClock = setInterval(() => {
          const colleagues = rootGetters['GET_COMPANYMATES'];
          if (!colleagues) return;
          colleagues.forEach((colleague) => {
            socket.on(`status-${colleague.id}`, (payload) => {
              commit('setLastStatusUpdate', Date.now());
              eventBus.$emit(`status-${colleague.id}`, payload);
            });
            socket.on(`status-agent-${colleague.id}`, (payload) => {
              commit('setLastStatusUpdate', Date.now());
              eventBus.$emit(`status-agent-${colleague.id}`, payload);
            });
            socket.on(`peerStatus-${colleague.endpoint}`, (payload) => {
              commit('setLastStatusUpdate', Date.now());
              eventBus.$emit(`peerStatus-${colleague.endpoint}`, payload);
            });
            socket.on(`device-${colleague.id}`, (payload) => {
              commit('setLastStatusUpdate', Date.now());
              eventBus.$emit(`device-${colleague.id}`, payload);
            });
            socket.on(`updateWorkSchedule-${colleague.id}`, (payload) => {
              commit('setLastStatusUpdate', Date.now());
              eventBus.$emit(`updateWorkSchedule-${colleague.id}`, payload);
            });
          });
          clearInterval(intervalClock);
        }, 1000);
      } catch (e) {}
    },
    joinRoom({ commit, getters }, { room }) {
      getters.GET_SOCKET.emit(`join-room`, room);
    },
    leaveRoom({ commit, getters }, { room }) {
      getters.GET_SOCKET.emit(`leave-room`, room);
    },
  },
};

function listeningStates({ socket, inCall, commit, getters, endpoint, rootGetters, vue }) {
  socket.on('ringing-to', (payload) => {
    if (inCall) return;
    if (vue.isWebRTC && rootGetters['sip/sessionState'] === SessionState.Established) return;

    commit('SET_DATA', {
      payload,
      dialerState: 1,
    });
  });

  socket.on('call-failed', (payload) => {
    eventBus.$emit('call-failed', payload.message || 'Ligação não completada');
  });
  // LIGANDO PARA ALGUEM
  socket.on('ringing-from', (payload) => {
    if (inCall) return;
    if (!payload.to) return;
    eventBus.$emit('openDialer', false);
    commit('SET_DATA', {
      payload,
      dialerState: 2,
    });
  });

  // LIGAÇÃO EM CURSO
  socket.on('oncall', (payload) => {
    const currentLinkedId = getters['GET_CURRENT_CALL'];
    if (currentLinkedId && currentLinkedId !== payload.linkedid) return;
    if (vue.isWebRTC && rootGetters['sip/sessionState'] !== SessionState.Established) return;
    if (
      !vue.isWebRTC &&
      getters.GET_DIALER_STATE !== dialer.CALLING &&
      getters.GET_DIALER_STATE !== dialer.INCOMING_CALL &&
      getters.GET_DIALER_STATE !== dialer.IN_CALL
    )
      return;
    commit('SET_ONCALL_DATA', payload);
    commit('SET_IS_IN_CONTEXT', true);
    vue.$router.push({
      name: 'ContextArea',
      params: { callId: payload.linkedid },
    });
    eventBus.$emit('UX-event', {
      eventName: 'Dialer Oncall',
      payload,
    });
  });

  // LIGAÇÃO TRANSFERIDA
  socket.on('transfer', (payload) => {
    if (vue.isWebRTC && rootGetters['sip/sessionState'] !== SessionState.Established) return;
    commit('SET_TRANSFER_DATA', payload);
    eventBus.$emit('UX-event', {
      eventName: 'Dialer Transfer',
      payload,
    });
  });

  // INICIO DE TRANSFERENCIA
  /*this.socket.on("init-transfer", (payload) => {
  Vue.$log.info("INIT TRANSFER");
  this.socketMessage = payload;
  this.currentDialState = this.dialState.INCOMING_CALL;
});*/
  // LIGAÇÃO FINALIZADA
  socket.on('peerhangup', (payload) => {
    if (vue.isWebRTC && rootGetters['sip/sessionState'] !== SessionState.Terminated) return;
    if (inCall && payload.linkedid !== getters.GET_CURRENT_CALL) return;
    inCall = false;
    commit('setSpier', '');
    commit('DESTROY');
    eventBus.$emit('UX-event', {
      eventName: 'Dialer Hangup',
      payload,
    });
  });

  socket.on('queueanswer', (payload) => {
    if (!payload.peer) return;
    commit('SET_QUEUE_DATA', payload);
    eventBus.$emit('UX-event', {
      eventName: 'Dialer Queue Answer',
      payload,
    });
  });

  socket.on('queuesummary', (payload) => {
    commit('SET_QUEUE_SUMMARY', payload);
  });

  socket.on('queue-wait-list', (waitList) => {
    commit('SET_QUEUE_WAIT_LIST', waitList);
  });

  socket.on(`join-queue`, (payload) => {
    commit('ADD_WAIT_LIST', payload);
  });
  socket.on('left-queue', (payload) => {
    commit('REMOVE_WAIT_LIST', payload);
  });

  socket.on('worker-left', (payload) => {
    commit('setLastStatusUpdate', Date.now());
    commit('REMOVE_FROM_WORKER_LIST', payload);
  });
  socket.on('worker-joined', (payload) => {
    commit('setLastStatusUpdate', Date.now());
    commit('ADD_TO_WORKER_LIST', payload);
  });
  socket.on(`peerStatus-${endpoint}`, (payload) => {
    commit('SET_IS_DEVICE_CONNECTED', !!payload.online);
  });

  return inCall;
}

function listeningStatesChat(socket, commit, Vue) {
  const volume = Vue.$store.getters['userPreferences/getAudioVolume'];
  socket.on('chat.message.pickup', (payload) => {
    bell.volume = volume;
    bell.play();
    const showMessagesNotification = localStorage.getItem('@yunique:show-messages-notification');
    if (showMessagesNotification == 'true')
      notifyMe('Atenção!', 'Aguardando resposta para um novo atendimento de chat');
    commit('ADD_CHAT_NOTIFY', payload.message);
    eventBus.$emit('UX-event', { eventName: 'Chat Message Notification' });
    setTimeout(() => {
      commit('REMOVE_CHAT_NOTIFY', payload.message.id);
    }, payload.config.timeLimit * 1000);
  });

  socket.on('chat.message.deliver', (message) => {
    eventBus.$emit(`new-message`, message);
    const chatProviders = process.env.VUE_APP_CHAT_PROVIDERS;
    const emailProviders = process.env.VUE_APP_EMAIL_PROVIDERS;

    if (message?.recipientId && message?.status)
      Vue.$store.commit('chat/increaseUnreadMessagesCountByRoomId', message.chatRoomId);
    if (message?.recipientId)
      Vue.$store.commit('chat/trackMostRecentMessage', {
        chatRoomId: message.chatRoomId,
        timestamp: message.createdAt,
      });

    if (Vue?.$router?.currentRoute?.name === 'Chat' && !document.hidden) return;
    const showMessagesNotification = localStorage.getItem('@yunique:show-messages-notification');

    if (showMessagesNotification != 'true') return;
    if (Notification.permission !== 'granted') return Notification.requestPermission();

    let notificationTitle = `Nova mensagem de (${message.origin})`;
    if (emailProviders.includes(message.provider)) notificationTitle = `Nova mensagem no assunto (${message.topic})`;

    const messageBody =
      message.messageType == 'email' ? JSON.parse(message.messageBody).visibleText : message.messageBody;

    const notification = new Notification(notificationTitle, {
      body: messageBody,
      icon: require('@/assets/images/opens-app-logo-for-notification.png'),
    });

    notification.onclick = function (e) {
      if (chatProviders.includes(message.provider)) Vue.$router.push({ name: 'Chat' });
      if (emailProviders.includes(message.provider)) Vue.$router.push({ name: 'Emails' });
      parent.focus();
      e.target.close();
    };

    messageAlertSound.volume = volume;
    messageAlertSound?.play();
  });
}

function notifyMe(title, message) {
  if (!document.hidden && document.hasFocus()) return;
  if (Notification.permission !== 'granted') Notification.requestPermission();
  const notification = new Notification(title, {
    icon: require('@/assets/images/opens-app-logo-for-notification.png'),
    body: message,
  });
  notification.onclick = function (e) {
    parent.focus();
    //window.focus(); //just in case, older browsers
    e.target.close();
  };
}

function debouncedQueueUpdate(state) {
  const queueName = Object.values(state.debounceQueueWorkersToUpdate)[0].queue;
  for (const payload of Object.values(state.debounceQueueWorkersToUpdate)) {
    state.queueSummary[payload.queue].members[payload.memberIndex].name = payload.name;
    state.queueSummary[payload.queue].members[payload.memberIndex].status = payload.status;
  }
  state.debounceQueueWorkersToUpdate = [];
  state.queueSummary[queueName].members.sort((a, b) => a.status - b.status || a.name.localeCompare(b.name));
}
