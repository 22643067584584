<template lang="pug">
Overlay
  template(v-slot:content)
    .bg-white.flex.flex-col.py-4.pb-8.px-10(class="w-3/5 min-w-[400px] max-w-[700px]")
      .flex.space-between.w-full.py-4.border-b.border-zinc-200
        h4.text-2xl.text-gray-500.font-semibold.font-inter {{ $store.getters['chat/rooms'].length >= chatsLimit ? 'Limite de conversas atingido' : 'Iniciar conversa' }}
      .flex.flex-col.justify-center.items-center.w-full.gap-4
        .flex.flex-col.justify-start.items-start.w-full.w-full
          span.font-inter.text-sm.text-gray-400.font-medium(v-if="$store.getters['chat/rooms'].length < chatsLimit") Para iniciar, escolha o contato que você deseja conversar
          .flex.border-red-500.rounded-md.border-2.p-2.mt-2(v-if="$store.getters['chat/rooms'].length >= chatsLimit")
            p.text-red-500.font-semibold O limite de conversas ativas foi atingido, caso queira iniciar uma nova conversa encerre as converas ativas ou peça a um usuário admin alterar o limite
          dropdown-clients(
            :model="client",
            :labelTitle="'Contato'",
            @value="setValue",
            :attr="'name'",
            :noFilter="true"
            :displayAttr="'name'",
            :jsonData="clistContacts",
            :useId="true",
            :dataFilter="'name'",
            :placeholder="'Selecione um contato'",
            @intersect="intersected",
            :contact-error="contactConflicted",
            @data-selected="dataSelected"
            v-if="$store.getters['chat/rooms'].length < chatsLimit"
          )
          span.font-inter.text-sm.text-gray-400.font-medium.mt-6(v-if="$store.getters['chat/rooms'].length < chatsLimit")  Escolha a partir de qual número você iniciará a conversa
          .mt-4.px-4.py-3.flex.w-full.justify-between.gap-2.cursor-pointer.rounded.border-2(
            v-if="$store.getters['chat/rooms'].length < chatsLimit"
            v-for="(data, index) in wabas",
            :class="{ 'border-2 border-brand-400': data.id == waba.id, 'hover:bg-zinc-100 dark:hover:bg-zinc-900 dark:border-zinc-700': data.id != waba.id, 'border-red-400': wabaError }",
            @click="selectWaba(data)",
            class="dark:invert"
          )
            .font-inter.text-gray-800.text-base(class="dark:invert") {{ data.configurations.phone }}
            .font-inter.text-gray-400.text-base.ml-auto(class="dark:invert") Waba id: {{ data.configurations.wabaId }}
            .h-6.w-6.border.border-2.rounded-full.flex.items-center.justify-center(
              :class="data.id == waba.id ? 'bg-brand-500 border-brand-500' : ''"
            )
              .material-icons.notranslate.text-sm.text-white check

        .flex.items-start.w-full.gap-4.flex-col(v-if="$store.getters['chat/rooms'].length < chatsLimit")
          .flex.gap-2.cursor-pointer(@click='isDisplayingAdvancedChatSettings=!isDisplayingAdvancedChatSettings')
            p.text-sm.text-brand.font-semibold Configurações avançadas
            i.material-icons.notranslate.text-brand {{ (isDisplayingAdvancedChatSettings)?'expand_less':'expand_more' }}

          .flex.flex-col.w-full.items-start(v-if="isDisplayingAdvancedChatSettings")
            .flex.flex-col.gap-2.w-full.items-start
              .flex.gap-2.w-full
                p.text-sm.text-zinc-500.font-semibold Tempo máximo de inatividade:
                input.flex-1(type="range" min="3600" max="604800" step="3600" v-model.number="chatInactivityLimit")
              p.text-xs.text-zinc-500 Esse atendimento será finalizado automáticamente após {{ chatInactivityLimit/3600 }} hora(s) de inatividade pelo contato.
        .flex.w-full.items-start.justify-center.gap-2
          v-button-secondary(
            @click="$store.commit('modal/setActiveModal', '')"
          ) Cancelar
          v-button(@click="startChat()" v-if="$store.getters['chat/rooms'].length < chatsLimit") Iniciar conversa
</template>

<script>
import { debounce } from '@/helpers';
import Overlay from '@/components/Modals/Overlay.vue';
import chatConfigService from 'src/services/chat-config.js';
import chatService from 'src/services/chat.js';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import DropdownClients from '@/components/Dropdown/dropdown-clients.vue';
import Dropdown from '@/components/Dropdown/dropdown.vue';
import VButton from '../Buttons/v-button.vue';
import contactList from 'src/services/contact-list.js';
import SimpleInput from '@/components/Inputs/SimpleInput.vue';
import cservice from '@/services/cservice';

export default {
  components: {
    SimpleInput,
    VButtonSecondary,
    Overlay,
    VButton,
    Dropdown,
    DropdownClients,
  },
  data() {
    return {
      wabas: [],
      templates: [],
      waba: {},
      client: {},
      selectedTemplate: {},
      variables: {},
      page: 1,
      wabaError: false,
      oldName: '',
      contactSearchValue: '',
      clistContacts: [],
      currentPage: 1,
      contactConflicted: false,
      chatInactivityLimit: 86400,
      isDisplayingAdvancedChatSettings: false,
      chatsLimit: 25,
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
  },
  watch: {
    contactSearchValue(val) {
      this.searchTable();
    },
  },
  methods: {
    dataSelected(data) {
      this.contactConflicted = false;
    },
    setValue(val) {
      this.contactSearchValue = val;
    },
    async intersected() {
      const page = this.page;

      const data = await contactList.getContactsByIdentifier({
        offset: (page - 1) * 20,
        limit: (page - 1) * 20 + 20,
        filter: this.contactSearchValue.toLowerCase(),
      });

      this.page++;
      this.clistContacts = [...this.clistContacts, ...data];
    },
    searchTable: debounce(async function () {
      this.contactConflicted = false;
      if (this.oldName != this.contactSearchValue) {
        this.page = 1;
      }
      const page = this.page;

      if (!this.contactSearchValue) {
        this.clistContacts = await contactList.getAllContactsQuery({
          offset: 0,
          limit: 20,
        });
        return;
      }
      const contacts = await contactList.getContactsByIdentifier({
        offset: (page - 1) * 20,
        limit: (page - 1) * 20 + 20,
        filter: this.contactSearchValue.toLowerCase(),
      });
      this.clistContacts = contacts.contacts;
      this.oldName = this.contactSearchValue.toLowerCase();
    }, 500),
    async startChat() {
      if (this.$store.getters['chat/rooms'].length >= this.chatsLimit)
        return this.$toast.error('O limite de conversas ativas foi atingido');
      const { getContactById } = contactList;
      const { createRoom } = chatService;
      if (!this.waba.id) {
        this.$toast.error('Você deve selecionar um número para continuar.');
        this.wabaError = true;
        setTimeout(() => {
          this.wabaError = false;
        }, 3000);
        return;
      }
      if (!this.client.id) return this.$toast.error('Você deve selecionar um contato para continuar.');
      const params = {
        associations: ['phone'],
      };
      const contact = await getContactById(this.client.id, { params });
      let data;
      let from = contact.phones[0]?.ddi + contact.phones[0]?.number;
      if (contact.phones[0].number.startsWith('55')) from = contact.phones[0]?.number;

      if (from.length == 12) {
        // treatment cellphone without nine digit
        let firstChar = from.substring(4, 5);
        if (firstChar >= 5) {
          // Is Cellphone
          from = from.substring(0, 4) + 9 + from.substring(4, from.length);
        }
      }
      try {
        data = await createRoom({
          companyId: this.user.company_id,
          createdBy: contact.id,
          subject: this.waba.configurations.phone,
          creatorProvider: 'whatsapp',
          chatConfigId: this.waba.id,
          inactivityLimit: this.chatInactivityLimit,
          status: 'established',
          members: [
            {
              recipientId: this.user.id,
              provider: 'opens',
              companyId: this.user.company_id,
              channel: this.user.id,
              origin: this.user.id,
            },
            {
              recipientId: contact.id,
              provider: 'whatsapp',
              companyId: this.user.company_id,
              channel: from,
              origin: from,
              providerAccessToken: this.waba.configurations.phoneId,
              subject: this.waba.configurations.phone,
            },
          ],
        });
      } catch (error) {
        if (
          error?.response?.data?.message ===
          'Você não pode iniciar uma conversa com esse usuário pois ele já está em atendimento'
        ) {
          this.contactConflicted = true;
          this.$toast.error(error?.response?.data?.message);
          return;
        }
        console.error(error);
      }

      if (!data) return;
      this.$router.push({
        name: 'Chat',
        query: {
          templateModal: true,
        },
      });
      this.$store.commit('chat/setActiveRoom', {
        roomId: data.id,
        room: data,
      });
      this.$store.commit('modal/setActiveModal', 'StartChatTemplate');
    },
    selectWaba(waba) {
      if (waba == this.waba) {
        this.waba = {};
        return;
      }
      this.waba = waba;
    },
  },
  async created() {
    if (this.context.contact) this.client = this.context.contact;
    const { getConfigsByProviderId } = chatConfigService;
    const { getAllContactsQuery } = contactList;
    const { getCompanieConfigByKey } = cservice;
    try {
      try {
        const res = await getCompanieConfigByKey('chat-limit');
        this.chatsLimit = res.data.value;
      } catch (e) {
        this.chatsLimit = 25;
      }
      this.clistContacts = await getAllContactsQuery({ offset: 0, limit: 20 });
      const data = await getConfigsByProviderId(process.env.VUE_APP_WHATSAPP_PROVIDER_CONFIG_ID, { inbound: true });
      this.wabas = data.configurations;
      if (this.wabas.length == 1) this.waba = this.wabas[0];
    } catch (e) {
      this.$toast.error(e);
    }
  },
};
</script>
