import queuePreferencesService from '@/services/queue-preference.js';

export default {
  namespaced: true,
  state: {
    queuesPreference: {},
    currentAttendanceRequiresTag: false,
    currentEmailAttendanceRequiresTag: false,
    currentChatAttendanceRequiresTag: false,
  },
  getters: {
    QUEUES_PREFERENCE(state) {
      return (queueName) => state.queuesPreference[queueName];
    },
    CURRENT_ATTENDANCE_REQUIRES_TAG(state) {
      return state.currentAttendanceRequiresTag;
    },
    CURRENT_EMAIL_ATTENDANCE_REQUIRES_TAG(state) {
      return state.currentEmailAttendanceRequiresTag;
    },
    CURRENT_CHAT_ATTENDANCE_REQUIRES_TAG(state) {
      return state.currentChatAttendanceRequiresTag;
    }
  },
  mutations: {
    SET_QUEUES_PREFERENCE(state, payload) {
      const queueName = payload.queueName?.trim();
      const queuesPreference = {
        ...state.queuesPreference,
        [queueName]: { requiresTag: payload.mandatoryTagAfterCall },
      };

      state.queuesPreference = { ...queuesPreference };
    },
    SET_CURRENT_ATTENDANCE_REQUIRES_TAG(state, currentAttendanceRequiresTag) {
      state.currentAttendanceRequiresTag = currentAttendanceRequiresTag;
    },
    SET_CURRENT_EMAIL_ATTENDANCE_REQUIRES_TAG(state, currentEmailAttendanceRequiresTag) {
      state.currentEmailAttendanceRequiresTag = currentEmailAttendanceRequiresTag;
    },
    SET_CURRENT_CHAT_ATTENDANCE_REQUIRES_TAG(state, currentChatAttendanceRequiresTag) {
      state.currentChatAttendanceRequiresTag = currentChatAttendanceRequiresTag;
    },
  },
  actions: {
    async fetchQueuePreferences({ commit }, { queueName, companyId }) {
      queueName = queueName?.trim();
      try {
        if (!queueName) return;
        const response = await queuePreferencesService.getQueuePreferences({
          companyId,
          queueId: queueName,
        });

        const queuePreferences = response || [];
        if (!queuePreferences) return;
        if (!queuePreferences?.length) return;

        const mandatoryTagAfterCall = queuePreferences?.[0]?.mandatoryTagAfterCall;
        commit('SET_QUEUES_PREFERENCE', { queueName, mandatoryTagAfterCall });
        commit('SET_CURRENT_ATTENDANCE_REQUIRES_TAG', mandatoryTagAfterCall);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchGroupPreferences({ commit }, { groupId, companyId }) {
      groupId = groupId?.trim();
      try {
        if (!groupId) return;
        const response = await queuePreferencesService.getGroupPreferences({
          companyId,
          groupId: groupId,
        });

        const groupPreferences = response || [];
        if (!groupPreferences || !groupPreferences?.length) return;

        const mandatoryTagAfterEmail = groupPreferences?.[0]?.mandatoryTagAfterEmail;
        const mandatoryTagAfterChat = groupPreferences?.[0]?.mandatoryTagAfterChat;

        commit('SET_CURRENT_EMAIL_ATTENDANCE_REQUIRES_TAG', mandatoryTagAfterEmail);
        commit('SET_CURRENT_CHAT_ATTENDANCE_REQUIRES_TAG', mandatoryTagAfterChat);     
      } catch (error) {
        console.error(error);
      }
    },
  },
};
