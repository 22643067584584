<template lang="pug">
.callback-page
  cancel-activity-modal(
    @cancel-activity="removeCallBack",
    @close-modal="closeModal",
    :activity="actualActivity",
    v-if="actualModal === 'CancelActivity'"
  )
  finish-activity-modal(
    @finish-activity="finishCallBack",
    @close-modal="closeModal",
    :activity="actualActivity",
    v-if="actualModal === 'FinishActivity'"
  )
  assigned-activity-modal(v-if="actualModal === 'AssignedActivity'" @close-modal="actualModal = ''")
  overlay-activity-detail(@close-overlay="overlay = ''" :activityData="overlayActivity" v-if="overlay === 'ActivityDetail'")
  h1.text-3xl.text-zinc-700.title-Text Próximas atividades
  h2.subtitle-Text Lista de próximas atividades de retorno
  .context-right-side-header
    div(
      class="context-tracking-tab-box",
      :class="selectedTab === tabsEnum.myCallbacks ? 'tracking-tab-selected' : ''",
      @click="selectTab(tabsEnum.myCallbacks)"
    )
      div(
        class="context-tracking-tab-text",
        :class="selectedTab === tabsEnum.myCallbacks ? 'tracking-tab-selected' : ''"
        ) Minhas Atividades({{ myCallbacksTotal }})
    div(
      class="context-tracking-tab-box",
      :class="selectedTab === tabsEnum.notAssigned ? 'tracking-tab-selected' : ''",
      @click="selectTab(tabsEnum.notAssigned)"
    )
      div(
        class="context-tracking-tab-text",
        :class="selectedTab === tabsEnum.notAssigned ? 'tracking-tab-selected' : ''"
      ) Não Atribuídas({{ abandonCallbacksPaginationTotal }})
    div(
      class="context-tracking-tab-box",
      :class="selectedTab === tabsEnum.calls ? 'tracking-tab-selected' : ''",
      @click="selectTab(tabsEnum.calls)"
    )
      div(
        class="context-tracking-tab-text",
        :class="selectedTab === tabsEnum.calls ? 'tracking-tab-selected' : ''"
      ) Chamadas
    div(
      class="context-tracking-tab-box",
      :class="selectedTab === tabsEnum.chats ? 'tracking-tab-selected' : ''",
      @click="selectTab(tabsEnum.chats)"
    )
      div(class="context-tracking-tab-text" :class="selectedTab === tabsEnum.chats ? 'tracking-tab-selected' : ''") Chat       
    div(
      class="context-tracking-tab-box",
      :class="selectedTab === tabsEnum.emails ? 'tracking-tab-selected' : ''",
      @click="selectTab(tabsEnum.emails)"
      v-if="isAllowedToUseEmail"
    )
      div(class="context-tracking-tab-text", :class="selectedTab === tabsEnum.emails ? 'tracking-tab-selected' : ''") Email       
    
    .filling-context-tracking-tab-box

  .container-fluid(v-if="selectedTab === tabsEnum.calls || selectedTab === tabsEnum.chats || selectedTab === tabsEnum.emails")
    .header.flex.gap-4(style="margin-top: 24px" v-if="selectedTab === tabsEnum.calls")
      period-filter(class="w-1/4" @filter="filterPeriod" v-if="selectedTab === tabsEnum.calls")

      input(
        type="text"
        class="w-1/4 bg-[#f8f9fa] border border-solid border-[#e9ecef] rounded h-12 indent-3 text-base font-inter placeholder:text-gray-300 py-2 px-1 focus:bg-white focus:border-[#aaa] transition-all duration-300 ease-linear"
        v-model="contactNameOrNumberValue"
        @input="filterContactsAndCompanyMatesByIdentifier"
        placeholder="Nome ou número"
      )

      queue-filter(class="w-1/4" @filter="filterQueue" :options="queueOptions" v-if="selectedTab === tabsEnum.calls")

      v-button-secondary(
        class="font-inter flex ml-auto"
        v-if="selectedTab === tabsEnum.calls"
        @click="advancedFiltersModalOpen = true"
      )
        span.text-brand.material-icons.mr-2(class="dark:invert-0") filter_list
        span.text-base Mais filtros

      modal(@click="advancedFiltersModalOpen = false" v-if="advancedFiltersModalOpen && selectedTab === tabsEnum.calls")
        modal-body(class="min-w-[50%]")
          template(v-slot:header)
            .flex.justify-between
              h4.text-2xl.text-gray-500.font-semibold.font-inter Filtros avançados
              v-button-secondary(@click="resetAdvancedFilters")
                span.text-base Limpar filtros avançados
          template(v-slot:body)
            section
              label.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Tipo de atividade 
              dropdown-filter(
                class="w-full z-50"
                @filter="setActivityFilterValues"
                :activity-selected="activitySelected"
                :options="activityTypes"
              )

            section
              label.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Tag
              tag-filter.w-full(
                @filter="filterTag"
                :tag-selected="tagSelected"
                :options="tagOptions"
              )

            section
              label.block.text-base.text-gray-500.font-semibold.font-inter.pt-2 Protocolo
              input(
                type="text"
                class="w-full bg-[#f8f9fa] border border-solid border-[#e9ecef] rounded h-12 indent-3 text-base font-inter placeholder:text-gray-300 py-2 px-1 focus:bg-white focus:border-[#aaa] transition-all duration-300 ease-linear"
                v-model="callProtocolFilterValue",
                placeholder="Protocolo",
              )

          template(v-slot:footer)
            .flex.gap-4
              v-button-secondary(type="button" @click="advancedFiltersModalOpen = false") Fechar
              v-button-primary(type="button" @click="applyAdvancedFilters") Aplicar

    .history-table-content(:class="{'pt-6' : selectedTab === tabsEnum.calls}" v-if="!loading")
      historic-table(v-if="selectedTab === tabsEnum.calls" :calls="calls")
      historic-table(v-if="selectedTab === tabsEnum.chats" :chats="chats")
      historic-table(v-if="selectedTab === tabsEnum.emails" :emails="emails")
    .pagination-aligner(v-if="!loading")
      span.pagination-info Itens por página:
      select.pagination-select(v-model="activityQuantity" @change="changeActivitiesTable")
        option(value="5") 5
        option(selected="" value="10") 10
        option(value="15") 15
        option(value="20") 20
      span.pagination-size-text(v-if="selectedTab === tabsEnum.calls") {{ callsPaginationLabel }}
      span.pagination-size-text(v-if="selectedTab === tabsEnum.chats") {{ chatsPaginationLabel }}
      span.pagination-size-text(v-if="selectedTab === tabsEnum.emails") {{ emailsPaginationLabel }}
      span.pagination-icon.material-icons(ref="backIcon" @click="paginationBack") &#xe5cb
      span.pagination-icon.material-icons(@click="paginationForward" ref="forwardIcon") &#xe5cc
    .w-full.mt-8(v-if="loading")
      content-placeholders(v-for="item in +activityQuantity" :key="item" :rounded="true").w-full.mb-8.ml-4
        content-placeholders-img.w-full.h-14  

  section.w-full(v-else)
    table.callback-table
      thead.callback-thead
        tr
          th.callback-table-starter(role="columnheader" scope="col") Origem
          th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role="columnheader" scope="col") Ligar para
          th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric(role="columnheader" scope="col") Observações
          th.mdc-data-table__header-cell(role="columnheader" scope="col") Adicionado
          th.mdc-data-table__header-cell(role="columnheader" scope="col") Ações
      TransitionGroup.mdc-data-table__content(
        v-if="selectedTab === tabsEnum.notAssigned && loaded"
        tag="tbody"
        name="slide-fade"
        :duration="200"
        mode="out-in"
      )
        abandon-activities(
          v-for="data in abandonCallbacks",
          :key="data.id",
          :data="data",
          @openOverlay="openOverlay",
          @calling="call"
        )

      TransitionGroup.mdc-data-table__content(
        v-if="selectedTab === tabsEnum.myCallbacks && loaded",
        tag="tbody",
        name="slide-fade",
        :duration="200",
        mode="out-in"
      )
        assigned-activities(
          v-for="(data, index) in myCallbacks",
          :key="data.id",
          :data="data",
          @calling="call",
          @openOverlay="openOverlay",
          :index="index"
        )

    .w-full(v-if="loading")
      content-placeholders(v-for="item in 10" :key="item" :rounded="true").w-full.mb-6.ml-4
        content-placeholders-img(class='w-full h-20')

    .pagination-aligner.mt-8(v-if="!loading")
      span.pagination-info Itens por página:
      select.pagination-select(v-model="activityQuantity" @change="changeActivitiesTable")
        option(value="5") 5
        option(selected="" value="10") 10
        option(value="15") 15
        option(value="20") 20
      span.pagination-size-text(v-if="selectedTab === tabsEnum.notAssigned") {{ notAssignedPaginationLabel }}
      span.pagination-size-text(v-if="selectedTab === tabsEnum.myCallbacks") {{ myCallbacksPaginationLabel }}
      span.pagination-icon.material-icons(ref="backIcon" @click="paginationBack") &#xe5cb
      span.pagination-icon.material-icons(@click="paginationForward" ref="forwardIcon") &#xe5cc

</template>

<script>
import OverlayActivityDetail from './Callback.OverlayActivityDetail';
import CancelActivityModal from './Callback.CancelActivityModal';
import FinishActivityModal from './Callback.FinishActivityModal';
import AssignedActivityModal from './Callback.AssignedActivityModal';
import AbandonActivities from './Callbacks.Abandon.vue';
import AssignedActivities from './Callbacks.Assigned.vue';
import HistoricTable from './Callbacks.HistoricTable.vue';
import DropdownFilter from './Callbacks.History.DropdownFilter.vue';
import PeriodFilter from './Callbacks.History.PeriodFilter.vue';
import QueueFilter from './Callbacks.History.QueueFilter.vue';
import TagFilter from './Callbacks.History.TagFilter.vue';
import csService from '@/services/cservice.js';
import rhService from '@/services/rship';
import callReportService from '@/services/call-report';
import { getAllChatsOfMember, getTotalChatsCountOfMember } from '@/services/chat';
import callbackMixin from '@/mixins/callbacks.js';
import { debounce } from '@/helpers';
import contactList from '@/services/contact-list.js';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import EventBus from '@/main-event-bus';
import VButtonPrimary from '@/components/Buttons/v-button.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import {
  updateCallback,
  getCallBackById,
  getUserCallbacks,
  getCallbacks,
  countCallbacks,
  asignCallBack,
} from '@/services/cblist';

export default {
  title: 'Opens - Atividades',
  mixins: [callbackMixin],
  components: {
    OverlayActivityDetail,
    CancelActivityModal,
    FinishActivityModal,
    AssignedActivityModal,
    AssignedActivities,
    AbandonActivities,
    HistoricTable,
    DropdownFilter,
    PeriodFilter,
    QueueFilter,
    TagFilter,
    Modal,
    ModalBody,
    VButtonPrimary,
    VButtonSecondary,
  },
  data() {
    return {
      emailWhiteListedCompanies: process.env.VUE_APP_EMAIL_WHITE_LIST,
      selectedTab: 2,
      tabsEnum: {
        myCallbacks: 0,
        notAssigned: 1,
        calls: 2,
        chats: 3,
        emails: 4,
      },

      overlay: '',
      overlayActivity: {},
      actualActivity: {},
      actualModal: '',
      loaded: false,

      loading: true,
      activityQuantity: '10',
      periodFilterValue: {
        start: new Date('2000-05-06T17:05:47.796Z'),
        end: new Date('3000-05-06T17:05:47.796Z'),
      },
      callTagFilterValue: null,
      callToIdFilterValue: null,
      callFromIdFilterValue: null,
      callStatusFilterValue: '',
      callProtocolFilterValue: '',
      contactNameOrNumberValue: '',
      callQueueFilterValue: '',

      callFromNumberFilterValue: '',
      callToNumberFilterValue: '',

      activitySelected: 'Tipo de atividade',
      tagSelected: 'Tag',

      activityTypes: [
        {
          name: 'Answered Outgoing',
          id: 1,
          value: 'Chamada efetuada atendida',
        },
        {
          name: 'Answered Incoming',
          id: 2,
          value: 'Chamada recebida atendida',
        },
        {
          name: 'Unanswered Incoming',
          id: 3,
          value: 'Chamada recebida não atendida',
        },
        {
          name: 'Unanswered Outgoing',
          id: 4,
          value: 'Chamada efetuada não atendida',
        },
      ],
      textForCallType: {
        made: 'Chamada efetuada atendida',
        received: 'Chamada recebida atendida',
        missed: 'Chamada recebida não atendida',
        missed_outgoing: 'Chamada efetuada não atendida',
      },

      chats: [],
      chatsPaginationOffset: 0,
      chatsPaginationLimit: 10,
      chatsPaginationTotal: 0,

      emails: [],
      emailsPaginationOffset: 0,
      emailsPaginationLimit: 10,
      emailsPaginationTotal: 0,

      calls: [],
      callsPaginationOffset: 0,
      callsPaginationLimit: 10,
      callsPaginationTotal: 0,
      queueOptions: [],
      tagOptions: [],
      advancedFiltersModalOpen: false,

      myCallbacks: [],
      myCallbacksTotal: 0,
      myCallbacksSearchAfter: null,
      myCallbacksSearchBefore: null,
      myCallbacksPage: 1,
      myCallbacksNextScore: null,
      myCallbacksPreviousScore: null,

      abandonCallbacks: [],
      abandonCallbacksSearchAfter: null,
      abandonCallbacksSearchBefore: null,
      abandonCallbacksPage: 1,
      abandonCallbacksNextScore: null,
      abandonCallbacksPreviousScore: null,
      abandonCallbacksPaginationTotal: 0,
    };
  },
  async created() {
    this.identifyTabByUrl();

    try {
      await this.countCallbacks();
      this.loaded = true;

      this.callFromIdFilterValue = this.user.id;
      this.callToIdFilterValue = this.user.id;

      if (this.tabsEnum.chats === this.selectedTab) {
        const [chats, totalChats] = await Promise.all([
          this.fetchChats(),
          getTotalChatsCountOfMember({
            recipientId: this.user.id,
            companyId: this.user.company_id,
            provider: ['yunique', 'whatsapp'],
          }),
        ]);

        this.chats = chats;
        this.chatsPaginationTotal = totalChats.count;
      } else if (this.tabsEnum.calls === this.selectedTab) {
        const [calls, totalCalls, queues, tags] = await Promise.all([
          this.fetchCalls(),
          this.fetchCountPaginatedCalls(),
          csService.getCompanyQueues(),
          rhService.getTags(this.user.company_id),
        ]);
        this.callsPaginationTotal = totalCalls;
        this.queueOptions = queues;

        this.tagOptions = [{ id: 0, text: 'Sem tags' }, ...tags];

        this.calls = calls;
      } else if (this.tabsEnum.emails === this.selectedTab) {
        const [emails, totalEmails] = await Promise.all([
          this.fetchEmails(),
          getTotalChatsCountOfMember({
            recipientId: this.user.id,
            companyId: this.user.company_id,
            provider: ['outlook', 'gmail'],
          }),
        ]);

        this.emails = emails;
        this.emailsPaginationTotal = totalEmails.count;
      }
    } catch (error) {
      this.$toast.open({
        type: 'error',
        message: 'Falha ao buscar atividades',
      });
      this.$toast.open({
        type: 'error',
        message: 'Falha ao buscar histórico de chamadas',
      });
    } finally {
      this.loading = false;
    }
  },
  watch: {
    selectedTab(val) {
      if (val === this.tabsEnum.calls) {
        this.resetCallFilters();
      } else if (val === this.tabsEnum.chats) {
        this.resetChatFilters();
      } else if (val === this.tabsEnum.emails) {
        this.resetEmailFilters();
      } else if (val === this.tabsEnum.notAssigned) {
        this.resetAbandonCallbacksFilters();
      }
    },
    async chatsPaginationOffset(val) {
      try {
        if (this.selectedTab !== this.tabsEnum.chats) return;
        this.chats = await this.fetchChats();
        const totalChats = await getTotalChatsCountOfMember({
          recipientId: this.user.id,
          companyId: this.user.company_id,
          provider: ['yunique', 'whatsapp'],
        });
        this.chatsPaginationTotal = totalChats.count;
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar histórico de chats',
        });
      }
    },
    async emailsPaginationOffset(val) {
      try {
        if (this.selectedTab !== this.tabsEnum.emails) return;
        this.emails = await this.fetchEmails();
        const totalEmails = await getTotalChatsCountOfMember({
          recipientId: this.user.id,
          companyId: this.user.company_id,
          provider: ['outlook', 'gmail'],
        });
        this.emailsPaginationTotal = totalEmails.count;
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar histórico de emails',
        });
      }
    },
    async activityQuantity(val) {
      if (val == 'update') return;
      try {
        if (this.selectedTab === this.tabsEnum.calls) {
          await this.fetchCallsWithCount();
        } else if (this.selectedTab === this.tabsEnum.chats) {
          this.chats = await this.fetchChats();
          const totalChats = await getTotalChatsCountOfMember({
            recipientId: this.user.id,
            companyId: this.user.company_id,
            provider: ['yunique', 'whatsapp'],
          });
          this.chatsPaginationTotal = totalChats.count;
        } else if (this.selectedTab === this.tabsEnum.emails) {
          this.emails = await this.fetchEmails();
          const totalEmails = await getTotalChatsCountOfMember({
            recipientId: this.user.id,
            companyId: this.user.company_id,
            provider: ['outlook', 'gmail'],
          });
          this.emailsPaginationTotal = totalEmails.count;
        }
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar atividades',
        });
      }
    },
    async callsPaginationOffset(val) {
      try {
        if (this.selectedTab !== this.tabsEnum.calls) return;
        await this.fetchCallsWithCount();
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar histórico de chamadas',
        });
      }
    },
  },
  methods: {
    async selectTab(tab) {
      this.selectedTab = tab;

      this.activityQuantity = '10';

      const query = {
        tab: Object.keys(this.tabsEnum).find((key) => this.tabsEnum[key] === tab),
      };

      if (tab === this.tabsEnum.notAssigned) {
        this.resetAbandonCallbacksFilters();
        await this.countCallbacks();
        this.abandonCallbacks = [];
        await this.fetchAbandonCallbacks();
      } else if (tab === this.tabsEnum.myCallbacks) {
        this.resetMyCallbacksFilters();
        await this.countCallbacks();
        this.myCallbacks = [];
        await this.fetchMyCallbacks();
      }

      this.$router.push({
        query,
      });
    },
    async identifyTabByUrl() {
      switch (this.$route?.query?.tab) {
        case 'myCallbacks':
        case 'activity':
          this.$router.push({
            query: {
              tab: 'myCallbacks',
            },
          });
          this.selectedTab = this.tabsEnum.myCallbacks;
          this.resetMyCallbacksFilters();
          await this.fetchMyCallbacks();
          break;
        case 'calls':
          this.selectedTab = this.tabsEnum.calls;
          break;
        case 'chats':
          this.selectedTab = this.tabsEnum.chats;
          break;
        case 'emails':
          this.selectedTab = this.tabsEnum.emails;
          break;
        case 'notAssigned':
          this.selectedTab = this.tabsEnum.notAssigned;
          this.resetAbandonCallbacksFilters();
          await this.fetchAbandonCallbacks();
          break;
        default:
          this.$router.push({
            query: {
              tab: 'calls',
            },
          });
          this.selectedTab = this.tabsEnum.calls;
          break;
      }
    },
    resetCallFilters() {
      setTimeout(() => {
        this.activityQuantity = 'update';
      }, 1);
      setTimeout(() => {
        this.activityQuantity = 10;
      }, 1);
      this.callsPaginationLimit = 10;
      this.callsPaginationOffset = 0;
    },
    resetChatFilters() {
      setTimeout(() => {
        this.activityQuantity = 'update';
      }, 1);
      setTimeout(() => {
        this.activityQuantity = 10;
      }, 1);
      this.chatsPaginationLimit = 10;
      this.chatsPaginationOffset = 0;
    },
    resetEmailFilters() {
      setTimeout(() => {
        this.activityQuantity = 'update';
      }, 1);
      setTimeout(() => {
        this.activityQuantity = 10;
      }, 1);
      this.emailsPaginationLimit = 10;
      this.emailsPaginationOffset = 0;
    },
    resetAbandonCallbacksFilters() {
      this.activityQuantity = 10;
    },
    async fetchChats() {
      return await getAllChatsOfMember({
        recipientId: this.user.id,
        companyId: this.user.company_id,
        limit: this.activityQuantity,
        offset: this.chatsPaginationOffset,
        provider: ['yunique', 'whatsapp'],
      });
    },
    async fetchEmails() {
      return await getAllChatsOfMember({
        recipientId: this.user.id,
        companyId: this.user.company_id,
        limit: this.activityQuantity,
        offset: this.emailsPaginationOffset,
        provider: ['outlook', 'gmail'],
      });
    },
    async fetchCalls() {
      const payload = {
        limit: this.callsPaginationLimit,
        offset: this.callsPaginationOffset,
        createdAtLt: new Date(this.periodFilterValue.end).toISOString(),
        createdAtGt: new Date(this.periodFilterValue.start).toISOString(),
        companyId: this.user.company_id,
        fromId: this.callFromIdFilterValue,
        toId: this.callToIdFilterValue,
        queues: this.callQueueFilterValue,
        status: this.callStatusFilterValue,
        protocol: this.callProtocolFilterValue,
        fromNumber: this.callFromNumberFilterValue,
        toNumber: this.callToNumberFilterValue,
      };

      if (this.callTagFilterValue?.id === 0) {
        payload.noTag = true;
      } else {
        payload.tagIds = this.callTagFilterValue?.id;
      }

      EventBus.$emit('mixpanel-event', {
        eventName: 'filter-calls-callback',
        payload: {
          createdAtLt: payload.createdAtLt,
          createdAtGt: payload.createdAtGt,
        },
      });
      return await callReportService.getPaginateCalls(payload);
    },
    async fetchCountPaginatedCalls() {
      const payload = {
        limit: this.callsPaginationLimit,
        offset: this.callsPaginationOffset,
        createdAtLt: new Date(this.periodFilterValue.end).toISOString(),
        createdAtGt: new Date(this.periodFilterValue.start).toISOString(),
        companyId: this.user.company_id,
        fromId: this.callFromIdFilterValue,
        toId: this.callToIdFilterValue,
        queues: this.callQueueFilterValue,
        status: this.callStatusFilterValue,
        protocol: this.callProtocolFilterValue,
        fromNumber: this.callFromNumberFilterValue,
        toNumber: this.callToNumberFilterValue,
      };

      if (this.callTagFilterValue?.id === 0) {
        payload.noTag = true;
      } else {
        payload.tagIds = this.callTagFilterValue?.id;
      }

      return await callReportService.countPaginatedCalls(payload);
    },
    async fetchCallsWithCount() {
      const [calls, totalCalls] = await Promise.all([this.fetchCalls(), this.fetchCountPaginatedCalls()]);
      this.calls = calls;
      this.callsPaginationTotal = totalCalls;
    },
    async changeActivitiesTable() {
      if (this.selectedTab === this.tabsEnum.calls) {
        this.callsPaginationOffset = 0;
        this.callsPaginationLimit = parseInt(this.activityQuantity);
      } else if (this.selectedTab === this.tabsEnum.chats) {
        this.chatsPaginationOffset = 0;
        this.chatsPaginationLimit = parseInt(this.activityQuantity);
      } else if (this.selectedTab === this.tabsEnum.emails) {
        this.emailsPaginationOffset = 0;
        this.emailsPaginationLimit = parseInt(this.activityQuantity);
      } else if (this.selectedTab === this.tabsEnum.notAssigned) {
        this.resetAbandonCallbacksFilters();
        await this.fetchAbandonCallbacks();
      } else if (this.selectedTab === this.tabsEnum.myCallbacks) {
        this.resetMyCallbacksFilters();
        await this.fetchMyCallbacks();
      }
    },
    async paginationForward() {
      if (this.selectedTab === this.tabsEnum.calls) {
        if (this.callsPaginationOffset + this.callsPaginationLimit >= this.callsPaginationTotal) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.callsPaginationOffset + limit;
        this.callsPaginationLimit = limit;
        this.callsPaginationOffset = offset > this.callsPaginationTotal ? this.callsPaginationTotal : offset;
      }

      if (this.selectedTab === this.tabsEnum.chats) {
        if (this.chatsPaginationOffset + this.chatsPaginationLimit >= this.chatsPaginationTotal) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.chatsPaginationOffset + limit;
        this.chatsPaginationLimit = limit;
        this.chatsPaginationOffset = offset > this.chatsPaginationTotal ? this.chatsPaginationTotal : offset;
      }

      if (this.selectedTab === this.tabsEnum.emails) {
        if (this.emailsPaginationOffset + this.emailsPaginationLimit >= this.emailsPaginationTotal) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.emailsPaginationOffset + limit;
        this.emailsPaginationLimit = limit;
        this.emailsPaginationOffset = offset > this.emailsPaginationTotal ? this.emailsPaginationTotal : offset;
      }

      if (this.selectedTab === this.tabsEnum.notAssigned) {
        if (this.abandonCallbacksNextScore === null) return;
        this.abandonCallbacksSearchAfter = this.abandonCallbacksNextScore;
        this.abandonCallbacksSearchBefore = null;
        this.abandonCallbacksPage += 1;
        await this.fetchAbandonCallbacks();
      }

      if (this.selectedTab === this.tabsEnum.myCallbacks) {
        if (this.myCallbacksNextScore === null) return;
        this.myCallbacksSearchAfter = this.myCallbacksNextScore;
        this.myCallbacksSearchBefore = null;
        await this.fetchMyCallbacks();
      }
    },
    async paginationBack() {
      if (this.selectedTab === this.tabsEnum.calls) {
        if (this.callsPaginationOffset === this.tabsEnum.myCallbacks) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.callsPaginationOffset - limit;
        this.callsPaginationLimit = limit;
        this.callsPaginationOffset = offset < 0 ? 0 : offset;
      }

      if (this.selectedTab === this.tabsEnum.chats) {
        if (this.chatsPaginationOffset === this.tabsEnum.myCallbacks) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.chatsPaginationOffset - limit;
        this.chatsPaginationLimit = limit;
        this.chatsPaginationOffset = offset < 0 ? 0 : offset;
      }

      if (this.selectedTab === this.tabsEnum.emails) {
        if (this.emailsPaginationOffset === this.tabsEnum.myCallbacks) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.emailsPaginationOffset - limit;
        this.emailsPaginationLimit = limit;
        this.emailsPaginationOffset = offset < 0 ? 0 : offset;
      }

      if (this.selectedTab === this.tabsEnum.notAssigned) {
        if (this.abandonCallbacksPreviousScore === null) return;
        this.abandonCallbacksSearchBefore = this.abandonCallbacksPreviousScore;
        this.abandonCallbacksSearchAfter = null;
        this.abandonCallbacksPage -= 1;
        await this.fetchAbandonCallbacks();
      }

      if (this.selectedTab === this.tabsEnum.myCallbacks) {
        if (this.myCallbacksPreviousScore === null) return;
        this.myCallbacksSearchBefore = this.myCallbacksPreviousScore;
        this.myCallbacksSearchAfter = null;
        await this.fetchMyCallbacks();
      }
    },
    resetCallsPagination() {
      this.callsPaginationOffset = 0;
      this.callsPaginationLimit = 10;
    },
    setActivityFilterValues(data) {
      if (data === 'reset') {
        this.activitySelected = 'Tipo de atividade';
        this.callStatusFilterValue = '';
        this.callFromIdFilterValue = this.user.id;
        this.callToIdFilterValue = this.user.id;
        return;
      }

      this.activitySelected = data.value;

      this.callStatusFilterValue = data.name.toLowerCase().includes('unanswered') ? 'noanswer' : 'answered';
      this.callFromIdFilterValue = data.name.toLowerCase().includes('outgoing') ? this.user.id : null;
      this.callToIdFilterValue = data.name.toLowerCase().includes('incoming') ? this.user.id : null;
    },
    async filterQueue(data) {
      this.loading = true;
      this.callQueueFilterValue = data;
      await this.fetchCallsWithCount();
      this.loading = false;
    },
    async filterPeriod(data) {
      this.loading = true;
      this.periodFilterValue = data;
      await this.fetchCallsWithCount();
      this.loading = false;
    },
    filterTag(data) {
      if (data === 'reset') {
        this.callTagFilterValue = null;
        this.tagSelected = 'Tag';
        return;
      }

      this.callTagFilterValue = data;
      this.tagSelected = data.text;
    },
    async resetAdvancedFilters() {
      this.loading = true;

      this.resetCallsPagination();

      // resets
      // protocol
      this.callProtocolFilterValue = '';

      // activity type
      this.setActivityFilterValues('reset');

      // tag
      this.filterTag('reset');

      this.advancedFiltersModalOpen = false;

      await this.fetchCallsWithCount();

      this.loading = false;
    },
    async applyAdvancedFilters() {
      this.loading = true;

      this.resetCallsPagination();

      this.advancedFiltersModalOpen = false;

      await this.fetchCallsWithCount();

      this.loading = false;
    },
    filterContactsAndCompanyMatesByIdentifier: debounce(async function () {
      this.loading = true;
      this.resetCallsPagination();

      if (this.contactNameOrNumberValue.length === 0) {
        this.callFromNumberFilterValue = '';
        this.callToNumberFilterValue = '';
        await this.fetchCallsWithCount();
        this.loading = false;
        return;
      }

      const contactsNumbers = await this.getContactsPhoneNumbersByIdentifier({
        filter: this.contactNameOrNumberValue.toLowerCase(),
      });

      const matesNumbers = this.getMatesEndpointsByIdentifier({
        filter: this.contactNameOrNumberValue.toLowerCase(),
      });

      const allNumbers = [...contactsNumbers, ...matesNumbers];

      if (allNumbers.length === 0) {
        this.calls = [];
        this.callsPaginationTotal = 0;
        return;
      }

      this.callFromNumberFilterValue = allNumbers.join(',');
      this.callToNumberFilterValue = allNumbers.join(',');

      await this.fetchCallsWithCount();

      this.loading = false;
    }, 500),
    async getContactsPhoneNumbersByIdentifier({ filter }) {
      const { contacts } = await contactList.getContactsByIdentifier({
        limit: 500,
        filter,
      });

      const contactsNumbers = contacts.map((contact) => contact.phones.map((phone) => phone.number)).flat();

      return contactsNumbers;
    },
    getMatesEndpointsByIdentifier({ filter }) {
      const mates = this.$store.getters['GET_COMPANYMATES'].filter(
        (mate) =>
          (mate.username.toLowerCase().includes(filter) || mate.peer_create.includes(filter)) &&
          mate.id !== this.user.id,
      );

      const matesEndpoints = mates.map((mate) => mate.endpoint);

      return matesEndpoints;
    },
    translateCallType(item) {
      let numberToSearch = this.user.endpoint;

      const returnIcon = {
        ANSWERED: function (call) {
          if (call.from === numberToSearch) {
            return 'made';
          }
          return 'received';
        },
        NOANSWER: function (call) {
          if (call.from === numberToSearch) {
            return 'missed_outgoing';
          }
          return 'missed';
        },
      };
      let type = returnIcon[item.status];
      return type(item);
    },
    async call({ payload, index }) {
      const num = payload.contactNumber;
      try {
        if (this.$store.getters['sip/agent']) {
          this.$store.dispatch('sip/makeCall', { callNum: num });
        } else this.callNumber(num);
        await this.setResponsible(payload.id);
        this.abandonCallbacks.splice(index, 1);
      } catch (e) {
        this.$toast.error('Falha ao completar a ligação, tente novamente!');
      } finally {
        await this.countCallbacks();
        await this.fetchMyCallbacks();
      }
    },
    async setResponsible(id) {
      try {
        const res = await asignCallBack(this.user.id, id);
        this.$log.info(res);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchAbandonCallbacks() {
      if (!this.user.queues) return;

      this.loading = true;

      try {
        const queues = Object.keys(this.user.queues);

        const abandonRes = await getCallbacks({
          queues,
          categories: ['abandon'],
          searchAfter: this.abandonCallbacksSearchAfter,
          searchBefore: this.abandonCallbacksSearchBefore,
          limit: this.activityQuantity,
          notAssigned: true,
        });

        this.abandonCallbacksNextScore = abandonRes.nextCursor;
        this.abandonCallbacksPreviousScore = abandonRes.previousCursor;

        this.abandonCallbacks = abandonRes.data ?? [];

        const abandonCategoryId = abandonRes.data[0]?.Category.id;
        this.$store.commit('SET_ABANDONID', abandonCategoryId);
      } catch (e) {
        this.abandonCallbacks = [];
      } finally {
        this.loading = false;
      }
    },
    resetAbandonCallbacksFilters() {
      this.abandonCallbacksSearchAfter = null;
      this.abandonCallbacksSearchBefore = null;
      this.abandonCallbacksPage = 1;
    },
    async fetchMyCallbacks() {
      this.loading = true;

      try {
        this.myCallbacks = [];

        const response = await getCallbacks({
          limit: this.activityQuantity,
          searchAfter: this.myCallbacksSearchAfter,
          searchBefore: this.myCallbacksSearchBefore,
          assignedTo: [this.user.id],
        });

        this.myCallbacksNextScore = response.nextCursor;
        this.myCallbacksPreviousScore = response.previousCursor;

        this.myCallbacks = response.data;
      } catch (e) {
        this.$log.info(e);
      } finally {
        this.loading = false;
      }
    },
    resetMyCallbacksFilters() {
      this.myCallbacksSearchAfter = null;
      this.myCallbacksSearchBefore = null;
      this.myCallbacksPage = 1;
    },
    async countCallbacks() {
      try {
        const totalNotAssignedCallbacks = await countCallbacks({
          queues: Object.keys(this.user.queues),
          categories: ['abandon'],
          notAssigned: true,
        });
        const totalMyCallbacks = await countCallbacks({
          assignedTo: [this.user.id],
        });

        this.abandonCallbacksPaginationTotal = totalNotAssignedCallbacks;
        this.myCallbacksTotal = totalMyCallbacks;
      } catch (e) {
        this.$log.info(e);
      }
    },
    action({ actualModal, actualActivity }) {
      this.actualModal = actualModal;
      this.actualActivity = actualActivity;
      this.overlay = '';
    },
    openOverlay(activity) {
      this.overlayActivity = activity;
      this.overlay = 'ActivityDetail';
    },
    closeModal() {
      this.actualModal = '';
      this.overlay = '';
    },
  },
  computed: {
    isAllowedToUseEmail() {
      if (!this.emailWhiteListedCompanies) return true;
      return this.emailWhiteListedCompanies?.split(',').includes(this.user.company_id);
    },
    callsPaginationLabel(key) {
      const startIndex = this.callsPaginationOffset + 1;
      const endIndex = this.callsPaginationOffset + this.callsPaginationLimit;
      return `${startIndex} - ${endIndex} de ${this.callsPaginationTotal}`;
    },

    chatsPaginationLabel(key) {
      const startIndex = this.chatsPaginationOffset + 1;
      const endIndex = this.chatsPaginationOffset + this.chatsPaginationLimit;
      return `${startIndex} - ${endIndex} de ${this.chatsPaginationTotal}`;
    },

    emailsPaginationLabel(key) {
      const startIndex = this.emailsPaginationOffset + 1;
      const endIndex = this.emailsPaginationOffset + this.emailsPaginationLimit;
      return `${startIndex} - ${endIndex} de ${this.emailsPaginationTotal}`;
    },
    notAssignedPaginationLabel() {
      const startIndex =
        this.abandonCallbacksPage * parseInt(this.activityQuantity) - parseInt(this.activityQuantity) + 1;
      const endIndex =
        this.abandonCallbacksPaginationTotal < parseInt(this.activityQuantity)
          ? this.abandonCallbacksPaginationTotal
          : this.abandonCallbacksPage * parseInt(this.activityQuantity);
      return `${startIndex} - ${endIndex} de ${this.abandonCallbacksPaginationTotal}`;
    },
    myCallbacksPaginationLabel() {
      const startIndex = this.myCallbacksPage * parseInt(this.activityQuantity) - parseInt(this.activityQuantity) + 1;
      const endIndex =
        this.myCallbacksTotal < parseInt(this.activityQuantity)
          ? this.myCallbacksTotal
          : this.myCallbacksPage * parseInt(this.activityQuantity);
      return `${startIndex} - ${endIndex} de ${this.myCallbacksTotal}`;
    },
  },
  mounted() {
    this.$root.$on('action', this.action);
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.no-tag-margin {
  font-size: 12px !important;
  margin-left: 10px;
}

.callback-table {
  line-height: normal;
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}

.callback-origin-create-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 22px;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
  margin-left: 2.5px;
}

.callback-call-icon {
  display: flex !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: var(--green-default);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 22px !important;
  display: flex;
  pointer-events: all;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: 0.2s;
}

.callback-call-icon:hover {
  box-shadow: 0px 7px 16px -2px rgba(0, 0, 0, 0.2) !important;
}

.callback-add-content {
  padding-right: 10px;
}

.callback-action-icon {
  display: flex !important;
  pointer-events: all;
  align-items: center;
  justify-content: center;
  background: #adb5bd;
  cursor: pointer;
  width: 24px;
  color: #fff;
  font-size: 18px !important;
  margin-right: 10px;
  height: 24px;
  transition: 0.1s;
  border-radius: 50%;
}

.callback-action-icon:hover {
  background: #8e949b;
}

.callback-actions-icon-aligner {
  display: flex;
  align-items: center;
  flex-direction: row;
  pointer-events: none;
}

.callback-actions-content {
  pointer-events: none;
}

.callback-obs-content {
  max-width: 222px;
  max-height: 39px;
  padding-right: 15px;
  word-wrap: anywhere;
}

.callback-origin-content {
  padding-left: 16px;
  padding-right: 5px;
  width: fit-content;
}

.callback-origin-tag {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  background: rgba(74, 142, 244, 0.1);
  border-radius: 4px;
  width: max-content;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4a8ef4;
}

.callback-callto-content {
  padding-right: 5px;
}

.callback-callto-content-aligner {
  display: flex;
  flex-direction: row;
}

.callback-callto-info-aligner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.callback-callto-number-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 15px;
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}

.callback-callto-tag-margin {
  margin-top: 4px;
  margin-left: 10px;
}

.user-tag {
  padding: 2px 6px;
  height: max-content;
  line-height: normal;
  width: fit-content;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.1);
  border-radius: 25px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #495057;
}

.callback-callto-name-text {
  margin-bottom: 0px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 22px;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}

.callback-obs-content {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}

.callback-callto-user-img {
  background: var(--brand-color-op-1);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--brand-color);
  height: 40px !important;
  width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.callback-origin-text {
  margin-left: 2px;
  margin-bottom: 2.5px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}

.callback-table-starter {
  padding-left: 16px;
  height: 56px;
}

.callback-table-row {
  padding-left: 12.5px;
  cursor: pointer;
  border-bottom: 1px solid #e9ecef;
  height: 89px;
}

.callback-table-row:hover {
  background-color: #f8f8f8;
}

.callback-thead {
  width: 100%;
  height: 56px;
  background: #f5f5f5;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}

.callback-activity-content {
  width: 100%;
  padding-left: 12.5px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  height: 89px;
}

.callback-page {
  padding-right: 32px;
  width: 100%;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title-Text {
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  margin-top: 32px;
  font-weight: 600;
  font-size: 24px;
  color: #495057;
}

.subtitle-Text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: 8px;
  color: #495057;
}

.context-tracking-tab-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}

.tracking-tab-selected {
  font-weight: bold !important;
  border-color: var(--brand-color) !important;
}

.filling-context-tracking-tab-box {
  padding-left: 10px;
  width: 100%;
  border-bottom: 2px solid #e9ecef;
  display: flex;
  justify-content: flex-start;
  transition: 0.2s;
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #495057;
}

.context-tracking-tab-box {
  cursor: pointer;
  min-width: max-content;
  padding: 8px;
  border-bottom: 2px solid #e9ecef;
  transition: 0.2s;
}

.context-tracking-tab-box:hover {
  border-color: #f0d8d1;
}

.context-tracking-box {
  padding-bottom: 8px;
  padding-left: 10px;
  border-bottom: 2px solid #e9ecef;
  width: 80%;
}

.context-right-side-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 28px;
  margin-top: 32px;
}

.pagination-icon {
  color: #868e96;
  margin-left: 5px;
  cursor: pointer;
  font-size: 18px !important;
}

.pagination-forward-animation {
  animation: forwardAnimation 0.5s linear;
}

.pagination-back-animation {
  animation: backAnimation 0.5s linear;
}

@keyframes backAnimation {
  20% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(10px);
    opacity: 0;
  }

  80% {
    transform: translateX(-10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes forwardAnimation {
  20% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-10px);
    opacity: 0;
  }

  80% {
    transform: translateX(10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.pagination-icon:hover {
  color: var(--brand-color);
}

.pagination-select {
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868e96;
  outline: none;
}

.pagination-aligner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  justify-content: flex-end;
}

.pagination-size-text {
  margin-left: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868e96;
}

.pagination-info {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868e96;
}

.history-table-content {
  width: 100%;
  padding-bottom: 32px;
}

.tag {
  min-width: max-content !important;
}

.received {
  background: var(--green-default);
}

.missed {
  background: var(--red-default);
}

.history-search-input {
  height: 48px;
  width: 15%;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
  text-indent: 3px;
}
</style>
