<template lang="pug">
.flex.flex-col.gap-2.items-start.justify-center
  //- Loading
  .flex.w-72.h-72.bg-gray-300.rounded-md.animate-pulse(v-if="loadingAttachment", class='dark:bg-gray-700')
    .material-icons.notranslate.text-6xl.text-zinc-400.opacity-50 movie

  //- Error
  .flex.py-2.flex-col.gap-2(v-else-if="attachment?.error")
    .flex.gap-1 
      p.font-semibold(v-tooltip="attachment?.error") Erro ao carregar vídeo
      .material-icons.notranslate.text-sm.text-brand-300.cursor-pointer(@click="loadVideo" v-tooltip="'Tentar novamente'") refresh
    .material-icons.notranslate.text-red-400 error

  //- Success
  .flex.justify-center.items-start.relative.group(class="dark:invert", v-else)
    video.aspect-video.rounded-sm.w-96(
      ref="video"
      :src="videoUrl"
      controls
      controlslist="nodownload"
    )
</template>

<script>
import { getAttachmentById } from '@/services/records';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'attachment-video',

  data() {
    return {
      videoUrl: null,
      loadingAttachment: true,
    };
  },

  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async loadVideo() {
      try {
        this.loadingAttachment = true;
        const data = await getAttachmentById(this.attachment.id, this.attachment.provider);
        this.videoUrl = data.link;
      } catch (error) {
        this.attachment.error = error?.message ?? 'Erro ao carregar video';
      } finally {
        this.loadingAttachment = false;
      }
    },
  },

  mounted() {
    const userAudioVolume = localStorage.getItem('audioVolume');
    this.$nextTick(() => {
      if (!this.$refs?.video) return;
      this.$refs.video.volume = userAudioVolume || 0.5;
    });
  },

  created() {
    this.loadVideo();
  },
});
</script>
