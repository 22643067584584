<template lang="pug">
.mt-0.content
  callback-modal(
    @close-modal="actualModal = ''",
    v-if="actualModal === 'assignCallback'",
    :contact="callbackContact"
  )
  link(
    href="https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap",
    rel="stylesheet"
  )
  link(
    rel="stylesheet",
    href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"
  )
  meta(name="viewport", content="width=device-width,initial-scale=1.0")
  link(
    href="https://fonts.googleapis.com/css2?family=Inter&display=swap",
    rel="stylesheet"
  )
  .container-fluid.pb-10
    .pt-8.m-0.pagearea
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner
          a.title(style="margin: 0px") Contatos
      .header.flex.gap-8.items-start
        .w-full
          IconInput.w-full(label="Contato" :icon="'search'" v-model="contactSearchValue")
          p.text-xs.text-gray-500.font-plus.mt-1 Pesquise por qualquer campo do contato, nome, número, email, organização, CPF e categoria
        .flex.gap-4
          v-button.h-fit.min-w-fit(@click="showContactMassImportModal=true") Importar
            contact-mass-import-modal(v-if="showContactMassImportModal" @close='handleImportModalClose')
          v-button.h-fit.min-w-fit(onclick="location.href='#/contacts/add'") Adicionar contato
          v-button-secondary.min-w-fit(@click="$router.push({ name: 'Organizations' })") Ver organizações
      div(v-if="!loading").w-full.flex.flex-col.gap-4.pb-6
        contact-table.w-full(
          v-if="users",
          :users="users"
          :key="currentPage"
          @opencallback="openCallback",
        )
        pagination.ml-auto.mr-6(
          :perPage="limit",
          :totalItems="totalUsers"
          :currentPage="currentPage",
          :hasMorePages="hasMorePages",
          @pagechanged="onPageChange"
          @limit="setLimit",
        )
      .w-full.mt-8(v-if="loading")
        content-placeholders.w-full.mb-8.ml-4(
          v-for="item in 7",
          :key="item",
          :rounded="true"
        )
          content-placeholders-img.h-14.w-full
</template>
<script>
import { debounce } from '@/helpers';
import ProgressModal from '@/components/Modals/ProgressModal.vue';
import SuccessModal from '@/components/Modals/SuccessModal.vue';
import FailedModal from '@/components/Modals/FailedModal.vue';
import ContactDropdownInput from './Contact.DropdownInput.vue';
import ContactTable from './Contact.Table.vue';
import VButton from '@/components/Buttons/v-button.vue';
import CallbackModal from './Contact.CallbackModal.vue';
import Pagination from '@/components/Pagination.vue';
import contactList from '@/services/contact-list';
import IconInput from '@/components/Inputs/IconInput.vue';
import ContactMassImportModal from './ContactMassImportModal.vue';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
export default {
  title: 'Opens - Contatos',
  components: {
    ContactDropdownInput,
    SuccessModal,
    FailedModal,
    ProgressModal,
    ContactTable,
    CallbackModal,
    VButton,
    Pagination,
    IconInput,
    ContactMassImportModal,
    vButtonSecondary,
  },
  data: () => ({
    currentPage: 1,
    totalUsers: 0,
    modals: {
      RemoveContactSuccess: {
        modalText: 'Contato removido com sucesso.',
      },
      AddContactSuccess: {
        modalText: 'Contato adicionado com sucesso.',
      },
      SaveContactSuccess: {
        modalText: 'Alterações salvas com sucesso.',
      },
      AddCallbackSuccess: {
        modalText: 'Retorno atribuido com sucesso.',
      },
      AddContactFailed: {
        modalText: 'Falha ao adicionar contato.',
      },
      AddCallbackFailed: {
        modalText: 'Falha ao atribuir retorno.',
      },
      RemoveContactFailed: {
        modalText: 'Falha ao remover contato.',
      },
    },
    searched: [],
    usersData: [],
    oldName: '',
    limit: 10,
    users: null,
    noteValue: '',
    contactSearchValue: '',
    callback: {
      user: '',
      assignedTo: '',
    },
    actualModal: '',
    companyUserList: {},
    callbackContact: null,
    loading: true,
    showContactMassImportModal: false,
  }),
  computed: {
    hasMorePages() {
      const totalPages = Math.ceil(this.totalUsers / this.limit);
      return this.currentPage < totalPages;
    },
  },
  methods: {
    setLimit(value) {
      this.limit = value;
      this.resetPagination();
    },
    resetPagination() {
      this.currentPage = 1;
    },
    async onPageChange(page) {
      this.currentPage = page;

      if (this.contactSearchValue) {
        this.searchTable();
        return;
      }
      this.users = await this.getContacts(page * this.limit - this.limit);
      this.usersData = this.users;
    },
    openCallback(contact) {
      this.callbackContact = contact;
      this.actualModal = 'assignCallback';
      this.$store.commit('SET_ITEMID', contact.id);
    },

    passContactId(id) {
      this.$router.push({ name: 'ContactDetail', params: { id: id } });
    },

    async handleImportModalClose() {
      this.showContactMassImportModal = false;
      this.loading = true;
      this.users = await this.getContacts(1 * this.limit - this.limit);
      this.loading = false;
    },

    searchTable: debounce(async function () {
      try {
        if (this.$store.getters['GET_MODAL'] === null) {
          this.$store.commit('SET_MODAL', { name: 'Progress' });
        }

        if (this.oldName.toLowerCase() !== this.contactSearchValue.toLowerCase()) this.currentPage = 1;
        const page = this.currentPage;
        if (!this.contactSearchValue) {
          this.users = await this.getContacts(page * this.limit - this.limit);
          return;
        }

        const { contacts, total_count } = await contactList.getContactsByIdentifier({
          offset: page * this.limit - this.limit,
          limit: this.limit,
          filter: this.contactSearchValue.toLowerCase(),
        });

        this.users = contacts.sort((a, b) => a.name.localeCompare(b.name));
        this.totalUsers = +total_count;
        this.oldName = this.contactSearchValue.toLowerCase();
      } catch (error) {
        this.$toast.error('Erro ao buscar contatos, tente novamente mais tarde');
      } finally {
        this.loading = false;
        if (this.$store.getters['GET_MODAL'] === 'Progress') {
          this.$store.commit('SET_MODAL', null);
        }
      }
    }, 500),

    displayEditBox(nome) {
      let contactElement = this.$refs[nome];
      if (contactElement.style.display === 'flex') {
        contactElement.style.opacity = 0;
        setTimeout(heightMin, 150);
        function heightMin() {
          contactElement.style.display = 'none';
        }
      } else {
        contactElement.style.display = 'flex';
        setTimeout(heightMax, 1);
        function heightMax() {
          contactElement.style.opacity = 1;
        }
      }
    },
    async getContacts(offset) {
      const { getAllContactsPaginated } = contactList;
      const data = await getAllContactsPaginated({
        offset,
        limit: this.limit,
      });
      this.totalUsers = data.total - 1;
      return data.items;
    },
  },
  watch: {
    async limit(value) {
      this.users = await this.getContacts(this.currentPage * value - value);
      this.usersData = this.users;
    },
    contactSearchValue(value) {
      this.searchTable();
    },
  },
  async created() {
    const { getAllContactsPaginated } = contactList;
    try {
      if (this.$store.getters['GET_MODAL'] === null) {
        this.$store.commit('SET_MODAL', { name: 'Progress' });
      }
      this.companyUserList = this.$store.getters['GET_COMPANYMATES'];
      const data = await getAllContactsPaginated({ offset: 0, limit: 10 });
      this.usersData = data.items;
      this.users = data.items;
      this.totalUsers = data.total - 1;

      if (this.$store.getters['GET_MODAL'] === 'Progress') {
        this.$store.commit('SET_MODAL', null);
      }
      this.$forceUpdate();
      this.loading = false;
    } catch (error) {
      if (e.response.status === 401) {
        window.location.pathname = '/auth/login';
      }
      this.$toast.open({ type: 'error', message: 'Falha ao buscar contatos' });
    }
  },
};
</script>
<style>
.dot-typing {
  position: relative;
  left: 0px;
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dotTyping 1s infinite linear;
}

@keyframes dotTyping {
  0% {
    margin-bottom: 0px;
  }
  12.5% {
    margin-bottom: 5px;
  }
  25% {
    margin-bottom: 10px;
  }
  37.5% {
    margin-bottom: 5px;
  }
  50% {
    margin-bottom: 0px;
  }
  62.5% {
    margin-top: 5px;
  }
  75% {
    margin-top: 10px;
  }
  87.5% {
    margin-top: 5px;
  }
  100% {
    margin-top: 0px;
  }
}
.progress-modal {
  position: absolute;
  background: #212529;
  padding-left: 16px;
  display: flex;
  width: 206px;
  height: 56px;
  align-items: center;
}
.content {
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
}
.modal-tag {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #868e96 !important;
}
.return-modal {
  display: block;
  width: 717px;
  position: absolute;
  z-index: 9999;
  height: 533px;
  background: #ffffff;
  padding: 32px 48px;
  box-shadow: 0px 0px 10000px 10000px rgba(0, 0, 0, 0.4);
}
a:not(.md-button) {
  text-decoration: none !important;
}
.tableBox {
  box-shadow: none !important;
  overflow: auto !important;
}
.md-table-fixed-header-container {
  background: #f8f9fa;
  border-radius: 4px;
  transition: 0.3s;
}
tbody .md-table-row td {
  border-bottom: 1px solid #f8f9fa;
  border-top: 0px !important;
}
.md-table .md-table-fixed-header-active {
  border-bottom: none !important;
  box-shadow: 0px 1px 10px 0.001px !important;
  padding-right: 0px !important;
  transition: 0.3s;
}
.md-table-head-label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}
.list-user-img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: grey;
}
.md-table-cell-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  color: #495057;
}
md-table-cell {
  position: relative;
}
.md-table-content {
  max-height: 100% !important;
}
.elipsis-icon {
  cursor: pointer;
  box-shadow: none !important;
  background: none !important;
}
.contact-edit-box {
  display: none;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  width: 180px;
  position: fixed;
  margin-left: -60px;
  opacity: 0;
  height: 111px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  transition: 0.2s;
}
.contact-option {
  cursor: pointer;
  background: #fff;
  width: 178px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #868e96;
  transition: height 0.2s;
}
.contact-option:hover {
  background: #f8f9fa;
}
</style>
