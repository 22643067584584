<template lang="pug">
.flex.w-full.items-center.justify-between.p-2(
  class="hover:bg-gray-100 cursor-pointer transition-all"
  @click="$emit('selectContact', data)"
)
  .flex.gap-2.items-center.justify-center
    .h-11.w-11.rounded-full.bg-brand-200.flex.items-center.justify-center
      .material-icons.notranslate.text-brand-400 mail
    p.truncate(
      class="max-w-[12rem]"
      :title="data.address"
    ) {{ data.address }}
  .flex.gap-2.items-center.justify-center
    .material-icons.notranslate.text-gray-400.text-sm person
    p.text-gray-400.text-sm.truncate(
      class="max-w-[10rem]"
      :title="data.contact.name"
    ) {{ data.contact.name }}
    .flex.rounded-full.bg-brand-400.p-1.items-center.justify-center.w-6(v-if="selected")
      .material-icons.text-xs.notranslate.text-white done
</template>

<script>
export default {
  props: ['data', 'selected'],
  data() {
    return {};
  },
};
</script>
