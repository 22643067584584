<template>
  <div v-if="loaded" class="content">
    <CallbackModal
      @close-modal="actualModal = ''"
      v-if="actualModal === 'assignCallback'"
      :contact="callbackContact"
    ></CallbackModal>
    <div class="container-fluid">
      <div class="pt-8 m-0 pagearea">
        <div style="align-items: flex-end; margin-bottom: 32px" class="header">
          <div class="incall-left-aligner" style="flex-direction: column; display: flex; width: fit-content">
            <div @click="$router.push({ name: 'Atividades' })" role="button" style="flex-direction: row; display: flex">
              <span style="padding-left: 0px; min-width: 0px; width: 20px" class="arrow-icon notranslate material-icons"
                >arrow_backward</span
              >
              <a style="margin-top: 3px" class="back-text">Voltar</a>
            </div>
            <a style="margin: 0px" class="title">Detalhe da atividade</a>
          </div>
        </div>
        <ActivityCard :data="activityCardData"></ActivityCard>
        <div style="margin-top: 24px" class="history-contact-box">
          <ContactBox @openCallback="openCallback" :userData="identityContact"></ContactBox>
          <CallEventTracking :call="activityCardData" :contact="identityContact"></CallEventTracking>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventDetail from '../EventDetail.vue';
import ActivityCard from './Callbacks.History.ActivityCard';
import CallbackModal from '@/pages/Contacts/Contact.CallbackModal.vue';
import ContactBox from '../Callbacks/Callbacks.History.ContactBox.vue';
import CallEventTracking from '@/components/CallEventTracking';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default {
  title: 'Opens - Detalhes da atividade',
  mixins: [UserOrContactMixin],
  data() {
    return {
      loaded: false,
      userData: null,
      activity: null,
      actualModal: '',
      callbackContact: {},
    };
  },
  components: {
    EventDetail,
    ActivityCard,
    CallEventTracking,
    ContactBox,
    CallbackModal,
  },
  computed: {
    linkedid() {
      return this.$route.params.id;
    },
    activityCardData() {
      return { ...this.activity, contact: this.identityContact };
    },
  },
  methods: {
    async getContact() {
      const fromId = this.activity?.fromId;
      const isMineCall = fromId === this.user.id || this.activity.from === this.user.endpoint;
      const callerId = isMineCall ? this.activity.toId : fromId;
      const callerNumber = isMineCall ? this.activity.toId : this.activity.from;

      await this.setContactOrOperator({
        id: callerId,
        number: callerNumber,
      });
      this.loaded = true;
    },
    openCallback(contact) {
      this.callbackContact = contact;
      this.actualModal = 'assignCallback';
      this.$store.commit('SET_ITEMID', contact.id);
    },
    async fetchData() {
      try {
        const res = await this.$http.get(this.requestLinks.callreport + '/call/id/' + this.linkedid);
        this.activity = res.data;
      } catch (error) {
        this.$log.info(error);
      } finally {
        await this.getContact();
      }
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>
<style></style>
