<template lang="pug">
td(colspan="9", v-if="openedByCallback")
  .event-detail
    .context-area-left-side
      protocol-box(
        v-if="chatData?.room?.protocol"
        :protocol="chatData?.room?.protocol"
      )
      .fields-aligner
        v-dropdown.w-44.mb-2(placement="bottom")
          button(
            class="group hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10 flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none"
          )
            .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") Ações
            span(class="material-icons notranslate group-hover:text-brand text-gray-400") keyboard_arrow_down
          template(#popper)
            ul(class="w-full divide-y")
              dropdown-item(@click="openTagModal", icon="f05b", label="Adicionar tag")
              dropdown-item(@click="openModal('NotesChat')", icon="e873", label="Adicionar anotação")
              dropdown-item(
                @click="openCaseModal()",
                icon="e85d",
                label="Vincular Caso",
                v-if="plan.cases"
              )
        label.text Tags
        .flex.justify-start.gap-2.w-96(
          class="xl:flex-wrap xl:overflow-x-hidden overflow-x-scroll"
        )
          tag-chat(@close="isTagModalOpen = false", @new-tag="newTag", v-if="isTagModalOpen")
          call-tag.min-w-fit(
            :call-tag="data",
            :key="index",
            @tag-removed="removeTag",
            type="chat",
            v-for="(data, index) in chat.room?.tags"
          )
          .text-gray-400.font-inter(v-show="!chat.room?.tags[0]") Nenhuma tag nesta atividade
        .input-box(v-if="messages?.length >= 1")
          label.text Histórico de mensagens
          .border.rounded.overflow-y-scroll.overflow-x-hidden.max-h-64
            .max-h-40.overflow-scroll.p-4
              message-card(
                :key="message.id",
                :members="members",
                :message="message",
                v-for="message in messages.slice(0, 3)"
                v-if="chatProviders.includes(chatData.room.creatorProvider)"
              )
              .flex.flex-col.gap-2.w-96
                email-message(
                  :key="message.id"
                  :contact-channel="chatData.room.members.filter((member) => member.provider != 'opens')[0].channel"
                  :data="message"
                  :is-from-history="true"
                  :is-most-recent="false"
                  v-for="(message, index) in messages.slice(0, 3)"
                  v-if="emailProviders.includes(chatData.room.creatorProvider)"
                )
            .bg-white.w-full.p-2.text-brand.font-medium.text-center(v-show="messages.length >= 1")
              v-button-secondary(
                @click="$store.commit('modal/setActiveModal', { name: 'MessageHistory', context: { chatRoomId: chatData.chatRoomId  } })",
                class="!text-sm"
              ) Ver histórico completo
    events-list(
      :id="chatId"
      :initial-date="chat.createdAt"
      :is-chat="true"
    )

div(v-else)
  .event-detail
    .context-area-left-side
      protocol-box(
        v-if="chatData?.room?.protocol"
        :protocol="chatData?.room?.protocol"
      )

      .fields-aligner
        v-dropdown.w-44.mb-2(placement="bottom")
          button(
            class="group hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10 flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none"
          )
            .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") Ações
            span(class="material-icons notranslate group-hover:text-brand text-gray-400") keyboard_arrow_down
          template(#popper)
            ul(class="w-full divide-y")
              dropdown-item(@click="openTagModal", icon="f05b", label="Adicionar tag")
              dropdown-item(@click="openModal('NotesChat')", icon="e873", label="Adicionar anotação")
              dropdown-item(
                @click="openCaseModal()",
                icon="e85d",
                label="Vincular Caso",
                v-if="plan.cases"
              )
        label.text Tags
          .w-full.flex.flex-wrap.justify-start
            tag-chat(@close="isTagModalOpen = false", @new-tag="newTag", v-if="isTagModalOpen")
            call-tag.mr-2.my-2(
              :call-tag="data",
              :key="index + data.id",
              @tag-removed="removeTag",
              type="chat",
              v-for="(data, index) in chat.room?.tags"
            )
            .text-gray-400.font-inter(v-show="!chat.room?.tags[0]") Nenhuma tag nesta atividade
        .input-box(v-if="messages?.length >= 1")
          label.text Histórico de mensagens
          .border.rounded.overflow-y-hidden.overflow-x-hidden.max-h-64
            .max-h-40.overflow-hidden.p-4
              message-card(
                :key="index",
                :members="members",
                :message="message",
                v-for="(message, index) in messages.slice(0, 3)"
                v-if="chatProviders.includes(chatData.provider)"
              )
              .flex.flex-col.w-96.gap-2
                email-message(
                  :key="message.id"
                  :data="message"
                  :is-from-history="true"
                  :is-most-recent="false"
                  v-for="(message, index) in messages.slice(0, 3)"
                  v-if="emailProviders.includes(chatData.provider)"
                )
            .bg-white.w-full.p-2.text-brand.font-medium.text-center(v-show="messages?.length >= 1")
              v-button-secondary(
                @click="$store.commit('modal/setActiveModal', { name: 'MessageHistory', context: { chatRoomId: chatData.chatRoomId } })",
                class="!text-sm"
              ) Ver histórico completo
    events-list(
      :id="chatId"
      :initial-date="chat.createdAt"
    )
</template>

<script>
import TagInput from '@/components/Inputs/TagInput.vue';
import VButtonSecondary from './Buttons/v-button-secondary.vue';
import UserContactBox from '@/pages/ContextArea/ContextArea.UserContactBox.vue';
import ActivityType from '@/pages/ContextArea/ContextArea.ActivityType.vue';
import TagChat from '@/components/Modals/TagChat.vue';
import io from 'socket.io-client';
import chat from '@/services/chat';
import ChatText from '@/components/Chat/text.vue';
import ChatInvitationCancel from '@/components/Chat/invitation-cancel.vue';
import ChatInvitationReply from '@/components/Chat/invitation-reply.vue';
import ChatUra from '@/components/Chat/ura.vue';
import ChatVoice from '@/components/Chat/voice.vue';
import ChatImage from '@/components/Chat/image.vue';
import ChatDocument from '@/components/Chat/document.vue';
import rship from '@/services/rship';
import { Dropdown, hideAllPoppers } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import CallTag from './CallEventTracking/call-tag.vue';
import MessageCard from './Chat/message-card.vue';
import EmailMessage from '@/components/Email/message.vue';
import ProtocolBox from './ProtocolBox.vue';
import EventsList from '@/components/CallEventTracking/EventsList';

export default {
  data() {
    return {
      isTagModalOpen: false,
      tagValue: '',
      contact: {},
      initialChat: {},
      companyTags: [],
      socket: null,
      messages: [],
      members: null,
      shownCopiedTooltip: false,
    };
  },
  props: {
    chatData: {
      type: Object,
      default: () => ({}),
    },
    openedByCallback: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CallTag,
    UserContactBox,
    TagInput,
    ActivityType,
    ChatText,
    ChatVoice,
    ChatDocument,
    ChatUra,
    EmailMessage,
    ChatImage,
    ChatInvitationReply,
    ChatInvitationCancel,
    TagChat,
    VButtonSecondary,
    DropdownItem,
    MessageCard,
    ProtocolBox,
    VDropdown: Dropdown,
    EventsList,
  },
  computed: {
    emailProviders() {
      return process.env.VUE_APP_EMAIL_PROVIDERS;
    },
    chatProviders() {
      return process.env.VUE_APP_CHAT_PROVIDERS;
    },
    chat() {
      return this.initialChat;
    },
    chatId() {
      return this.chatData.chatRoomId;
    },
  },
  methods: {
    newTag(tags) {
      if (this.initialChat.room.tags) {
        tags.forEach((tag) => this.initialChat.room.tags.push(tag));
      } else {
        this.initialChat.room.tags = tags;
      }
    },
    removeTag(id) {
      this.initialChat.room.tags = this.chat.room?.tags.filter((tag) => tag.id !== id);
    },
    openCaseModal() {
      this.$store.commit('modal/setActiveModal', {
        name: 'linkCase',
        context: {
          contact: this.chat.origin,
          type: 'chat',
          callId: this.chat.room.id,
        },
      });
      hideAllPoppers();
    },
    openTagModal() {
      this.isTagModalOpen = true;
      this.$store.commit('modal/setContext', {
        callId: this.chat.chatRoomId,
        type: 'chat',
      });
      hideAllPoppers();
    },
    openModal(type) {
      this.$store.commit('modal/setActiveModal', type);
      this.$store.commit('modal/setContext', {
        callId: this.chat.chatRoomId,
        type: 'chat',
      });
      hideAllPoppers();
    },
    copyProtocol() {
      const clipboardData =
        event?.clipboardData || window?.clipboardData || event?.originalEvent?.clipboardData || navigator?.clipboard;
      clipboardData.writeText(this.chatData?.room?.protocol);
      this.shownCopiedTooltip = true;
      setTimeout(() => {
        this.shownCopiedTooltip = false;
      }, 1500);
    },
    socketConfig() {
      this.socket = io(`${this.requestLinks.rhistory}`, {
        query: { callId: this.chatId },
        reconnect: true,
      });

      this.socket.on('rh-update', (payload) => {
        switch (payload.eventType) {
          case 'protocol':
            this.$store.commit('rh/SET_PROTOCOL', payload.identifier);
            break;
          default: {
            this.activities.unshift(payload);
            this.$store.commit('rh/ADD_TO_TAGS', {
              payload,
              linkedid: this.chatId,
            });
          }
        }
      });
      this.socket.on('rh-delete', (payload) => {
        for (let i = 0; i < this.activities.length; i++) {
          if (this.activities.id === parseInt(payload, 10)) {
            this.activities.splice(i, 1);
          }
        }
      });
    },
    async getMessages() {
      try {
        const data = await chat.getMessagesByRoom({
          chatRoomId: this.chatId,
          companyId: this.user.company_id,
          limit: 3,
          order: 'asc',
        });
        this.messages = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMembers() {
      try {
        const res = await chat.findById(this.chatId, {
          companyId: this.user.company_id,
          includeMembers: true,
        });
        this.members = res.members;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    if (!this.chatData.chatRoomId) this.chatData.chatRoomId = this.chatData.room.id;
    if (!this.chatData.provider) this.chatData.provider = this.chatData.room.creatorProvider;
    this.getMessages();
    this.getMembers();
    this.socketConfig();

    this.initialChat = this.chatData;
    this.companyTags = await rship.getTags(this.user.company_id);
    if (!this.$store.getters['cstate/GET_CALL_PAYLOAD']) return;
  },
};
</script>
