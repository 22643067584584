<template lang="pug">
.flex.rounded-md.gap-1.flex-col
  //- Loading
  .flex.items-center.justify-center.w-full.w-36.h-36.bg-gray-300.rounded.animate-pulse(v-if="loadingAttachment", class='dark:bg-gray-700')
    .material-icons.notranslate.text-6xl.text-zinc-400.opacity-50 landscape
  
  //- Error
  .flex.w-full.py-2.flex-col.gap-2(v-else-if="attachment?.error")
    .flex.gap-1 
      p.font-semibold(v-tooltip="attachment?.error") Erro ao carregar imagem
      .material-icons.notranslate.text-sm.text-brand-300.cursor-pointer(@click="loadImage" v-tooltip="'Tentar novamente'") refresh
    .material-icons.notranslate.text-red-400 error

  //- Success
  .flex.gap-1.w-full.justify-center.items-center(v-else)
    img(:src="imageUrl" v-if="imageUrl" class="w-full h-auto rounded-md", @click="displayImage")
</template>

<script>
import { getAttachmentById } from '@/services/records.js';

export default {
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingAttachment: true,
      imageUrl: null,
    };
  },
  methods: {
    async displayImage() {
      this.$store.commit('modal/setActiveModal', {
        name: 'FullImage',
        context: `${this.imageUrl}`,
      });
    },
    async loadImage() {
      try {
        this.loadingAttachment = true;
        const data = await getAttachmentById(this.attachment.id, this.attachment.provider);
        this.imageUrl = data.link;
      } catch (error) {
        this.attachment.error = error?.message ?? 'Erro ao carregar imagem';
      } finally {
        this.loadingAttachment = false;
      }
    },
  },
  mounted() {
    this.loadImage();
  },
};
</script>
