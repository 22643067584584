<template lang="pug">
tooltip
  template(#popper)
    message-card(:message="replyingTo")
  .flex.justify-start.items-start.shadow.select-none.flex-col.border-l-4.border-brand-200.p-1.rounded.bg-white.bg-opacity-5.cursor-default.transition-all.max-h-28.overflow-hidden(
    class="hover:bg-opacity-10 dark:invert"
  )
    .flex.justify-start.w-full.gap-1.items-center
      .flex()
        i.material-icons-round.notranslate.text-4xl(v-if="isDocument") insert_drive_file
        img.rounded-sm.w-14.aspect-square.object-cover(
          v-if="replyingTo.messageType === 'image'",
          :src="imgBaseUrl + replyingTo.messageBody",
        )
        .flex(v-if="replyingTo.messageHeaderType === 'image'")
          img.rounded-sm.w-14.aspect-square.object-cover(
            :src="imgBaseUrl + replyingTo.messageHeader",
            class="hover:brightness-90 dark:invert-0"
            ref="image1"
            v-if="!image2Loaded"
            @load="image1Loaded = true"
          )
          img.rounded-sm.w-14.aspect-square.object-cover(
            :src="imgBaseUrl + replyingTo.messageHeader.replace(`${this.user.company_id}.`, `${this.user.company_id}:`)",
            class="hover:brightness-90 dark:invert"
            ref="image2"
            v-if="!image1Loaded"
            @load="image2Loaded = true"
          )
      .flex.flex-col.items-start.justify-start.gap-1
        span.text-xs.text-gray-300.text-left.font-bold.font-plus(class="dark:invert" v-if="replyingTo?.messageHeaderType === 'text'") {{ replyingTo.messageHeader }}
        span.text-xs.text-gray-300.text-left.font-plus(class="dark:invert" v-html="replyingTo.messageBody")
        span.text-xs.text-gray-400.text-left.font-plus(class="dark:invert" v-if="replyingTo?.messageFooter") {{ replyingTo.messageFooter }}
</template>

<script>
import { defineComponent } from 'vue';
import { Tooltip } from 'floating-vue';

export default defineComponent({
  name: 'replying',
  components: {
    Tooltip,
    MessageCard: () => import('@/components/Chat/message-card.vue'),
  },
  data() {
    return {
      image1Loaded: false,
      image2Loaded: false,
      imgBaseUrl: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}/uploads/`,
      translateMediaType: {
        audio: 'Audio',
        image: 'Image',
        document: 'Document',
        location: 'Location',
        voice: 'Voice',
        contact: 'Contact',
      },
      mediaIcons: {
        audio: 'audiotrack',
        image: 'image',
        document: 'insert_drive_file',
        location: 'location_on',
        voice: 'voice',
        contact: 'people',
      },
    };
  },
  props: {
    replyingTo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isDocument() {
      const DOCUMENTS_TYPES = ['audio', 'document', 'location', 'voice', 'contact'];
      return (
        DOCUMENTS_TYPES.includes(this.replyingTo.messageType) ||
        DOCUMENTS_TYPES.includes(this.replyingTo.messageHeaderType)
      );
    },
  },
});
</script>
